const baseUrl = '/client'

export default (axiosInstance) => {
  const getClientData = async () => {
    try {
      const result = await axiosInstance.get(baseUrl)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    getClientData,
  }
}
