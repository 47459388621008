import confirmation from '@/store/cart/cartWorkflows/common/steps/confirmation'
import type { ConfirmationWorkflow } from '@/components/Cart/Step/Confirmation/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { accessor } from '@/store'

const step: StepBuilder<ConfirmationWorkflow> = {
  ...confirmation,
  useStep(cart, workflow, record, useAction) {
    const useStep = confirmation.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const useWorkflow = useStep.useWorkflow()
        let component = useWorkflow.component

        if (accessor.session.getPermissionsDetails.find((detail) => detail.tag === 'ONECLICKCTR')) {
          component = () => import('@/components/Cart/Step/Confirmation/subs/PassToCard.vue')
        }

        return {
          ...useWorkflow,
          component,
        }
      },
    }
  },
}

export default step
