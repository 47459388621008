export default {
  paymentMeans: {
    prelevemetIntroText:
      'Le prélèvement automatique est soumis à validation de nos équipes. Veuillez renseigner vos informations, nous allons étudier votre demande',
    prelevementAutoNonActive: 'Prélèvement automatique à activer',
    prelevementAutoActive: 'Prélèvement automatique activé',
    paymentMeansEnabled: 'Modes de paiement disponibles',
    myPaymentMeans: 'Mes moyens de paiement',
    paymentDelay: 'Condition de règlement : Délai {paymentDelay} jour{paymentDelayPlural}',
    paymentDelivery: 'Condition de règlement : à la livraison',
    paymentWaiting: 'Condition de règlement : à reception de votre paiement',
    choosePrelevementAuto: 'Passer au prélèvement automatique',
    cb: {
      title: 'Par carte Bancaire',
      shortTitle: 'Carte Bancaire',
      info: 'Le paiement par carte bancaire peut être effectué uniquement avec <strong>des cartes bancaires françaises</strong> et pour les paiements <strong>de moins de 15 000€</strong>.',
      noAmericanExpress: 'Les cartes American Express ne sont pas acceptées.',
      selectYourCard: 'Sélectionner votre type de carte',
      cardNumber: 'Numéro de carte',
      endValidityDate: 'Date d’expiration',
      cvv: 'CVV',
      cvvTooltip: 'Le code de sécurité se situe au dos de votre carte et se compose de trois chiffres.',
    },
    check: {
      title: 'Par chèque',
      shortTitle: 'Chèque',
      sendAddressNext:
        'Merci d’envoyer votre chèque à l’adresse ci-contre lors de vos prochaines commandes par chèque.',
      sendAddress: 'Merci d’envoyer vos chèques à l’adresse ci-contre lors de vos commandes par chèque.',
      postalAddress: 'Adresse postale Edenred',
    },
    prelevement: {
      title: 'Par prélèvement automatique',
      shortTitle: 'Prélevement automatique',
      info: 'Vous avez activé le prélèvement automatique, le moyen de paiement le plus rapide pour le traitement de vos commandes.',
      bankInfo: 'Vos coordonnées bancaires',
      modify: 'Pour modifier vos coordonnées bancaires rendez-vous sur la page Paramètres > Moyens de paiement.',
      yourBankAccount: 'Votre compte bancaire débité',
      multipleFacturationPoints:
        'Conformément à votre demande, la facturation se fera en fonction du paramétrage de votre compte.',
    },
    directTransfert: {
      title: 'Par virement immédiat',
      shortTitle: 'Virement immédiat',
      info: 'Votre commande sera traitée instantanément. Vous serez automatiquement redirigé vers l’espace sécurisé de votre banque pour confirmer le paiement.',
    },
    transfert: {
      title: 'Par virement',
      shortTitle: 'Virement standard',
      transfertToEdenred: 'Merci de réaliser vos virements vers le compte bancaire Edenred ci-contre.',
      orderTracking:
        'Pensez à indiquer la référence de votre commande dans le libellé du virement afin d’accélérer son traitement par nos équipes.',
      orderPaid:
        'Votre commande sera traitée uniquement lorsque nous aurons reçu votre règlement. Pour gagner du temps, sélectionnez le virement immédiat à l’étape de paiement de votre commande.',
      edenredAccount: 'Coordonnées bancaires Edenred',
      yourAccount: 'Votre compte bancaire',
      upToDate:
        ' Vous avez ajouté vos coordonnées bancaires. Maintenez ces informations à jour pour assurer la rapidité de traitement de vos commandes Ticket Restaurant.',
      fillInYourBankAccount:
        'Pour vous reconnaître plus facilement, et ainsi accélérer le traitement de toutes vos commandes à venir, vous pouvez saisir vos coordonnées bancaires.',
      fillMyRib: 'Saisir mon RIB',
    },
    agreement: 'J’accepte les ',
    agreementCgv: 'Conditions Générales de Vente',
    quickWarning: 'Pour un traitement plus rapide de votre commande, la prochaine fois, pensez au virement immédiat !',
    loadingError: 'Une erreur est survenue lors du chargement du moyen de paiement.',
    loadingListError:
      'Une erreur est survenue lors du chargemet de la liste de vos moyens de paiement, merci de recharger la page',
    solvencyError:
      'Modification impossible car une ou plusieurs factures sont en attente de paiement. Retrouvez-les en cliquant ci-dessous:',
    solvencyInvoices: 'Voir mes factures',
    saveError: 'Une erreur technique nous empeche de sauvegarder votre demande: {saveError}',
    saveSuccess: 'Votre demande de modification a bien été prise en compte',
  },
}
