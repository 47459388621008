import { ref, type AsyncComponent, watch, computed } from 'vue'
import { Result } from '@badrap/result'
import type { IResponse } from 'swrv/dist/types'
import { useMixedPricings, usePricing } from '../usePricing'
import { type ConfirmationWorkflow, stepConfig } from '@/components/Cart/Step/Confirmation/workflow'
import { FrontCartStatus, type StepBuilder } from '@/store/cart/model'
import { accessor } from '@/store'
import habilitations from '@/variables/habilitations.json'
import { PaymentMean } from '@/services/carts/types'
import { CartStatus } from '@/services/carts/typesV2'
import { ProductCode } from '@/variables/ProductCode'
import { ActionType } from '@/types/Action'
import router from '@/router'
import { useProductLightItemTracking } from '@/composables/useCartTracking'
import { useStore as useTrackingStore } from '@/store/tracking'
import type { PricingData } from '@/types/Price'
import { useStore as useCartStore } from '@/store/cart/store'

const step: StepBuilder<ConfirmationWorkflow> = {
  config: stepConfig,
  isAllowed(cart) {
    return cart.remote.status === CartStatus.Closed
  },

  useStep(cart, _, __, useAction) {
    return {
      config: this.config,
      component: () => import('@/components/Cart/Step/Confirmation/index.vue'),
      useWorkflow() {
        const cartStore = useCartStore()
        let component: AsyncComponent = () => import('@/components/Cart/Step/Confirmation/subs/Default.vue')

        if (cart.remote.paymentOptions.paymentMean === PaymentMean.Virement) {
          const clientProduct = accessor.session.clientProducts[cart.meta.productCode]
          if (clientProduct == null || clientProduct.accountingSettings?.orderPaymentDeadline.maxDeadlineDays === 0) {
            component = () => import('@/components/Cart/Step/Confirmation/subs/ReminderTransfer.vue')
          }
        } else if (cart.meta.productCode === ProductCode.CARTE_KADEOS) {
          component = () => import('@/components/Cart/Step/Confirmation/subs/ActivateCard.vue')
        } else if (
          cart.remote.paymentOptions.paymentMean !== PaymentMean.PrelevementAutomatique &&
          accessor.session.hasPermission(habilitations.SETTINGS.PAYMENT_PRELEVEMENT)
        ) {
          component = () => import('@/components/Cart/Step/Confirmation/subs/PassToDebit.vue')
        }

        const trackingStore = useTrackingStore()

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let pricings: IResponse<PricingData, any>[] = []
        if (cart.meta.isMixed) {
          pricings = useMixedPricings(cart.remote.linkedCarts)
        } else {
          pricings = [usePricing(cart)]
        }

        const pricingsData = computed(() => {
          const data = pricings.map((pricing) => pricing.data.value).filter((pricing) => pricing)

          return data.length !== pricings.length ? null : data
        })

        watch(pricingsData, (pricingsData) => {
          if (pricingsData === null) {
            return
          }

          const item = useProductLightItemTracking(cart.meta.productCode, cart.meta.articleCode, cart.meta.isNominative)

          if (cart.status !== FrontCartStatus.Done) {
            const price = pricingsData.reduce(
              (r, pricing) => {
                if (pricing?.pricing) {
                  r.shipping += pricing.pricing.shippingFees
                  r.tax += pricing.pricing.vat
                  r.value += pricing.pricing.totalWithVAT
                }

                return r
              },
              {
                shipping: 0,
                tax: 0,
                value: 0,
              },
            )
            if (item) {
              trackingStore.trackEvent({
                id: 'transaction.confirmation',
                data: {
                  cart_id: cart.remote.id.toString(),
                  currency: 'EUR',
                  shipping: price.shipping / 100,
                  tax: price.tax / 100,
                  value: price.value / 100,
                  coupon: cart.remote.paymentOptions.discountCode ?? undefined,
                  items: [item],
                  transaction_id: `redcart_${cart.remote.redcartId}`,
                },
              })
            }

            trackingStore.trackEvent({
              id: 'product.purchased',
              data: null,
            })
            cart.status = FrontCartStatus.Done
            cartStore.setCart(cart)
          }
        })

        return {
          showHowTo: false,
          prevAction: useAction({
            name: 'home.page',
            id: 'btn-go-home',
            type: ActionType.Default,
            refresh: false,
            execute: async () => {
              await router.push('/')
              return Result.ok(true)
            },
          }),
          references: ref(cart.remote.description.orderReference ? [cart.remote.description.orderReference] : []),
          pricings,
          component,
        }
      },
    }
  },
}

export default step
