import { Result } from '@badrap/result'
import { ref } from 'vue'
import { useItemFormWorkflow } from './useItemFormWorkflow'
import type { Beneficiary } from '@/services/beneficiaries/types'
import { ActionType } from '@/types/Action'
import type { RemoteCart, UseAction } from '@/store/cart/model'
import { useVuelidateBeneficiaryForm } from '@/composables/useBeneficiaryFormWorkflow'
import type { BeneficiaryWorkflow } from '@/components/Cart/Module/Beneficiary/workflow'
import type { ApiError } from '@/services/common/errors'
import apiV4 from '@/services/apiV4'

export const useAddBeneficiaryWorkflow = (
  cart: RemoteCart,
  beneficiary: Beneficiary,
  useAction: UseAction,
  checkAddedBeneficiary = false,
): BeneficiaryWorkflow => {
  const add = ref(false)

  const { quantity, submit: submitItemForm, fetchCartItemRequest } = useItemFormWorkflow(cart)

  if (checkAddedBeneficiary) {
    apiV4.carts
      .fetchItems(cart.remote.id, { page: 1, pageSize: 1, beneficiaryIdFilter: beneficiary.id })
      .then((result) => {
        if (result.isOk && result.value.items.length === 1) {
          add.value = true
        }
      })
  }

  return {
    add,
    quantity,
    beneficiary,
    fetchCartItemRequest: () => {
      const { v$ } = useVuelidateBeneficiaryForm(cart.meta.productCode, cart.meta.isPlasticless, beneficiary)
      v$.value.$touch()
      if (v$.value.$invalid) {
        return null
      }
      return fetchCartItemRequest(beneficiary)
    },
    addAction: useAction({
      id: `add-${beneficiary.id}`,
      name: 'add',
      type: ActionType.Default,
      refresh: true,
      async execute() {
        const { v$ } = useVuelidateBeneficiaryForm(cart.meta.productCode, cart.meta.isPlasticless, beneficiary)
        v$.value.$touch()
        if (v$.value.$invalid) {
          return Result.err(new Error(v$.value.$errors[0].$message.toString()))
        }
        const result = await submitItemForm(beneficiary)
        if (result.isErr) {
          if ((result.error as ApiError).code === 'BeneficiaryAlreadyExist') {
            add.value = true
          }
          return Result.err(result.error)
        }
        return Result.ok(true)
      },
    }),
  }
}
