import { TR_NOMINATIF_OPTIONS, TR_NO_NOMINATIF } from '@/assets/constantes/tunnelsConfigs'
import univers from '@/assets/constantes/univers.json'
import { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'

const AnalysisView = () => import('@/views/uTr/Analysis/AnalysisView.vue')
const ConfirmationView = () => import('@/views/uTr/ConfirmationView.vue')
const NominativeOrderView = () => import('@/views/uTr/NominativeOrderView.vue')
const OptionsView = () => import('@/views/uTr/OptionsView/index.vue')
const OrderView = () => import('@/views/uTr/OrderView/index.vue')
const SummaryView = () => import('@/views/uTr/Summary/SummaryView.vue')
const CommandeView = () => import('@/views/Commande/index.vue')
const Tunnel = () => import('@/views/Tunnel.vue')
const StandAloneConfirmationView = () => import('@/views/uTr/StandAloneConfirmationView.vue')
const ImportInterim = () => import('@/views/Commande/tunnels/ImportInterim/index.vue')
const ConfirmationInterim = () => import('@/views/Commande/tunnels/ConfirmationInterim/index.vue')
const TunnelWrapper = () => import('@/views/Commande/tunnels/TunnelWrapper/index.vue')
const Options = () => import('@/views/Commande/tunnels/Options/index.vue')
const Import = () => import('@/views/Commande/tunnels/Import/index.vue')
const ConfirmationGenerique = () => import('@/views/Commande/tunnels/ConfirmationGenerique/index.vue')
const ChildView = () => import('@/views/DefaultRouter/Child/index.vue')
const Order = () => import('@/views/Commande/tunnels/OrderView/index.vue')
const Finalisation = () => import('@/views/Commande/tunnels/Finalisation/index.vue')
const SaisieWeb = () => import('@/views/Commande/tunnels/SaisieWeb/index.vue')

export default {
  path: univers.TR.ROOT.path,
  meta: univers.TR.ROOT.meta,
  // TODO: Replace with RootView when migrate univers
  component: CommandeView,
  children: [
    {
      path: univers.TR.UNIVERS.path,
      meta: univers.TR.UNIVERS.meta,
      name: univers.TR.UNIVERS.name,
      component: () => import('@/views/Universe/index.vue'),
    },
    {
      path: 'saisie-web',
      component: SaisieWeb,
      props: {
        productCode: 240,
        articleCode: 204,
        isNominatif: true,
      },
    },
    {
      path: univers.TR.NEW_CARTE_ROOT.path,
      component: TunnelWrapper,
      name: univers.TR.NEW_CARTE_ROOT.name,
      props: {
        univers: 'TR',
        hideStepperPaths: [univers.TR.NEW_CARTE_CONFIRMATION.path],
        hideHeadingPaths: [univers.TR.NEW_CARTE_CONFIRMATION.path],
        isNominatif: false,
        optionsRouteName: 'tunnelOptions',
        productCode: 240,
        articleCode: 204,
        headingTitle: 'Bienvenue dans <span class="product">l\'univers Ticket Restaurant®</span>',
        headingButton: 'howTo',
      },
      children: [
        {
          path: univers.TR.NEW_CARTE_OPTIONS.path,
          component: Options,
          name: univers.TR.NEW_CARTE_OPTIONS.name,
          step: {
            number: 1,
            label: 'Panier',
            icon: 'basket',
            isHidden: (config) => config.clientOptions.multiloadingAllowed,
          },
          props: {
            operationnalConfig: TR_NOMINATIF_OPTIONS,
          },
        },
        {
          path: univers.TR.NEW_CARTE_IMPORT.path,
          component: Import,
          name: univers.TR.NEW_CARTE_IMPORT.name,
          step: {
            number: 2,
            label: 'Commande',
            icon: 'user',
          },
        },
        {
          path: univers.TR.NEW_CARTE_FINALISATION.path,
          component: Finalisation,
          name: univers.TR.NEW_CARTE_FINALISATION.name,
          step: {
            number: 3,
            label: 'Paiement',
            icon: 'card-picto',
          },
        },
        {
          path: univers.TR.NEW_CARTE_CONFIRMATION.path,
          component: ConfirmationGenerique,
          name: univers.TR.NEW_CARTE_CONFIRMATION.name,
          step: {
            number: 4,
            label: 'Confirmation',
            icon: 'check',
            ishidden: true,
          },
        },
      ],
    },
    {
      path: univers.TR.CARTE_ROOT.path,
      component: ChildView,
      children: [
        {
          path: univers.TR.CARTE_CTR.path,
          meta: univers.TR.CARTE_CTR.meta,
          name: univers.TR.CARTE_CTR.name,
          component: Tunnel,
          props: {
            productCode: ProductCode.CARTE_TICKET_RESTAURANT,
            articleCode: ArticleCode.CARTE_TICKET_RESTAURANT,
            componentList: [OptionsView, NominativeOrderView, AnalysisView, SummaryView, ConfirmationView],
            headingTitle: '<strong>Cartes Ticket Restaurant®</strong> - Commande et chargement',
            tunnelName: 'CTR',
            stepper: [
              {
                number: 1,
                label: 'Panier',
                icon: 'basket',
                steps: [{ number: 1, label: 'Options' }],
              },
              {
                number: 2,
                label: 'Commande',
                icon: 'user',
                steps: [
                  { number: 2, label: 'Commande' },
                  { number: 3, label: 'Analyse' },
                ],
              },
              {
                number: 3,
                label: 'Paiement',
                icon: 'card-picto',
                steps: [{ number: 4, label: 'Finalisation' }],
              },
            ],
          },
        },
        {
          path: univers.TR.CARTE_INTERIM_ROOT.path,
          component: ChildView,
          children: [
            {
              path: univers.TR.CARTE_INTERIM_IMPORT.path,
              meta: univers.TR.CARTE_INTERIM_IMPORT.meta,
              name: univers.TR.CARTE_INTERIM_IMPORT.name,
              component: ImportInterim,
            },
            {
              path: univers.TR.CARTE_INTERIM_CONFIRMATION.path,
              meta: univers.TR.CARTE_INTERIM_CONFIRMATION.meta,
              name: univers.TR.CARTE_INTERIM_CONFIRMATION.name,
              component: ConfirmationInterim,
            },
          ],
        },
      ],
    },
    {
      path: univers.TR.PAPIER_ROOT.path,
      component: ChildView,
      children: [
        {
          path: univers.TR['PAPIER_NON-NOM'].path,
          meta: univers.TR['PAPIER_NON-NOM'].meta,
          name: univers.TR['PAPIER_NON-NOM'].name,
          component: Tunnel,
          props: {
            productCode: ProductCode.TICKET_RESTAURANT,
            articleCode: ArticleCode.NO_PRODUCT,
            componentList: [OrderView, SummaryView, ConfirmationView],
            headingTitle: 'Passer une commande de <strong>titres Ticket Restaurant&reg;</strong>',
            tunnelName: 'TR',
            stepper: [
              {
                number: 1,
                label: 'Panier',
                icon: 'basket',
                steps: [{ number: 1, label: 'Options' }],
              },
              {
                number: 2,
                label: 'Paiement',
                icon: 'card-picto',
                steps: [{ number: 2, label: 'Finalisation' }],
              },
            ],
          },
        },
        {
          path: univers.TR.PAPIER_NOM.path,
          meta: univers.TR.PAPIER_NOM.meta,
          name: univers.TR.PAPIER_NOM.name,
          component: Tunnel,
          props: {
            productCode: ProductCode.TICKET_RESTAURANT,
            componentList: [OptionsView, NominativeOrderView, AnalysisView, SummaryView, ConfirmationView],
            headingTitle: 'Passer une commande de <strong>titres Ticket Restaurant&reg;</strong>',
            tunnelName: 'TR',
            stepper: [
              {
                number: 1,
                label: 'Panier',
                icon: 'basket',
                steps: [{ number: 1, label: 'Options' }],
              },
              {
                number: 2,
                label: 'Commande',
                icon: 'user',
                steps: [
                  { number: 2, label: 'Commande' },
                  { number: 3, label: 'Analyse' },
                ],
              },
              {
                number: 3,
                label: 'Paiement',
                icon: 'card-picto',
                steps: [{ number: 4, label: 'Finalisation' }],
              },
            ],
          },
        },
      ],
    },
    {
      path: univers.TR.NEW_PAPIER_ROOT.path,
      component: ChildView,
      children: [
        {
          path: univers.TR['NEW_PAPIER_NON-NOM'].path,
          meta: univers.TR['NEW_PAPIER_NON-NOM'].meta,
          name: univers.TR['NEW_PAPIER_NON-NOM'].name,
          component: TunnelWrapper,
          props: {
            productCode: 1,
            articleCode: 0,
            isNominatif: false,
            hideStepperPaths: [univers.TR.NEW_CONFIRMATION_ROOT.path],
            hideHeadingPaths: [univers.TR.NEW_CONFIRMATION_ROOT.path],
            headingTitle: 'Passer une commande de <strong>titres Ticket Restaurant®</strong>',
            headingButton: 'howTo',
            univers: 'TR',
          },
          children: [
            {
              path: univers.TR['NEW_PAPIER_NON-NOM_ORDER'].path,
              component: Order,
              name: univers.TR['NEW_PAPIER_NON-NOM_ORDER'].name,
              step: {
                number: 1,
                label: 'Panier',
                icon: 'basket',
              },
              props: {
                operationnalConfig: TR_NO_NOMINATIF,
              },
            },
            {
              path: univers.TR['FINALISATION__ROOT'].path,
              component: Finalisation,
              name: univers.TR['FINALISATION__ROOT'].name,
              step: {
                number: 2,
                label: 'Paiement',
                icon: 'card-picto',
              },
            },
            {
              path: univers.TR.NEW_CONFIRMATION_ROOT.path,
              meta: univers.TR.NEW_CONFIRMATION_ROOT.meta,
              name: univers.TR.NEW_CONFIRMATION_ROOT.name,
              component: ConfirmationGenerique,
              step: {
                number: 3,
                label: 'confirmation',
                icon: 'card-picto',
                ishidden: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: univers.TR.CONFIRMATION_ROOT.path,
      meta: univers.TR.CONFIRMATION_ROOT.meta,
      name: univers.TR.CONFIRMATION_ROOT.name,
      component: StandAloneConfirmationView,
      props: (route) => {
        return {
          orderId: Number(route.query.orderId),
        }
      },
    },
  ],
}
