export const VALIDATION_ORDER_NAMES = {
  VF: 'facialValueRule',
  PL: 'deliveryPointRule',
  EVENT: 'eventRule',
  AMOUNT: 'amountRule',
  FORMAT: 'formatRule',
  MESSAGE: 'messageRule',
}

const rules = {
  [VALIDATION_ORDER_NAMES.FORMAT]: ({ value }) => {
    return !!value.value
  },
  [VALIDATION_ORDER_NAMES.EVENT]: ({ value }) => {
    return !!value.value
  },
  [VALIDATION_ORDER_NAMES.AMOUNT]: ({ value, error }) => {
    return !!value.value && !error
  },
  [VALIDATION_ORDER_NAMES.VF]: ({ value, error }) => {
    return !!value && !error
  },
  [VALIDATION_ORDER_NAMES.PL]: ({ value, error }) => {
    return !!value.value && !error
  },
  [VALIDATION_ORDER_NAMES.MESSAGE]: ({ error }) => {
    return !error
  },
}

export function checkRule(validator, value) {
  return rules[validator] && rules[validator](value)
}
