import { Result } from '@badrap/result'
import { ref } from 'vue'
import { useValueOfTitleWorkflow } from '../../composables/useValueOfTitleWorkflow'
import { useDeliveryPointWorkflow } from '../../composables/useDeliveryPointWorkflow'
import {
  type MixedCartSettingsWorkflow,
  moduleMixedConfig,
  type TrRecord,
} from '@/components/Cart/Module/Settings/workflow'
import type { FrontCartDataStore, ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import { useStore } from '@/store/cart/store'
import { ProductCode } from '@/variables/ProductCode'
import apiV4 from '@/services/apiV4'
import { DataStoreKey } from '@/services/dataStore/types'
import { useStore as useDrawerStore } from '@/store/drawer'
import { accessor } from '@/store'
import { ArticleCode } from '@/variables/ArticleCode'

const cartSetting: ModuleBuilder<MixedCartSettingsWorkflow> = {
  config: moduleMixedConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow({ showDelivery, showValueOfTitle }: TrRecord) {
        const dataStoreValueOfTitle = cart.meta.dataStore[DataStoreKey.ValueOfTitle]
        const dataStoreDeliveries = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
        const saveDefaultParams = ref(true)
        const isMultiValueOfTitle = ref(dataStoreValueOfTitle?.isMulti ?? false)
        const showDeliveryCtr = !cart.meta.isPlasticless
        const productCodes = [ProductCode.CARTE_TICKET_RESTAURANT, ProductCode.TICKET_RESTAURANT]

        const deliveryPointCtr = dataStoreDeliveries ? dataStoreDeliveries[ProductCode.CARTE_TICKET_RESTAURANT] : null
        const deliveryPointTr = dataStoreDeliveries ? dataStoreDeliveries[ProductCode.TICKET_RESTAURANT] : null

        return {
          showDelivery,
          showValueOfTitle,
          saveDefaultParams,
          employersContribution: dataStoreValueOfTitle?.employersContribution,
          isMultiValueOfTitle,
          valueOfTitleProductImg: cart.meta.visual.src,
          useValueOfTitleWorkflow: useValueOfTitleWorkflow(
            productCodes,
            'import',
            dataStoreValueOfTitle
              ? {
                  value: dataStoreValueOfTitle.value,
                  employersContribution: dataStoreValueOfTitle.employersContribution,
                }
              : undefined,
          ),
          deliveryProductImgCtr: accessor.session.getProductImg(
            ProductCode.CARTE_TICKET_RESTAURANT,
            ArticleCode.CARTE_TICKET_RESTAURANT,
          ).src,
          deliveryProductImgTr: accessor.session.getProductImg(ProductCode.TICKET_RESTAURANT, null).src,
          deliveryTitleCtr: 'cart.modules.settings.deliveryPoint.titleCard',
          deliveryTitleTr: 'cart.modules.settings.deliveryPoint.titlePaper',
          showDeliveryCtr,
          useCtrDeliveryPointWorkflow: useDeliveryPointWorkflow(cart, deliveryPointCtr, {
            productCode: cart.meta.productCode,
            showType: true,
            for: 'import',
            showCardBenefDescription: true,
          }),
          useTrDeliveryPointWorkflow: useDeliveryPointWorkflow(cart, deliveryPointTr, {
            productCode: ProductCode.TICKET_RESTAURANT,
            showType: true,
            for: 'import',
            showCardBenefDescription: false,
          }),
          submitAction: (submitValueOfTitle, submitTrDeliveryPoint, submitCtrDeliveryPoint) =>
            useAction({
              id: 'add',
              name: 'cart.modules.settings.save',
              type: ActionType.Default,
              refresh: true,
              async execute() {
                const dataStore: FrontCartDataStore = {
                  ...cart.meta.dataStore,
                }

                if (showValueOfTitle && submitValueOfTitle) {
                  let resultValueOfTitle = Result.ok({
                    value: cart.meta.dataStore[DataStoreKey.ValueOfTitle]?.value ?? 0,
                    employersContribution: cart.meta.dataStore[DataStoreKey.ValueOfTitle]?.employersContribution ?? 0,
                  })
                  if (!isMultiValueOfTitle.value) {
                    resultValueOfTitle = await submitValueOfTitle()
                  }

                  if (resultValueOfTitle.isErr) {
                    return Result.err(resultValueOfTitle.error)
                  }

                  dataStore[DataStoreKey.ValueOfTitle] = {
                    isMulti: isMultiValueOfTitle.value,
                    value: resultValueOfTitle.value.value,
                    employersContribution: resultValueOfTitle.value.employersContribution,
                    save: saveDefaultParams.value,
                  }

                  if (saveDefaultParams.value) {
                    await apiV4.dataStore.putDataStoreByProduct(
                      DataStoreKey.ValueOfTitle,
                      cart.meta.productCode,
                      cart.meta.articleCode,
                      dataStore[DataStoreKey.ValueOfTitle],
                      true,
                    )
                  } else {
                    await apiV4.dataStore.deleteDataStoreByProduct(
                      DataStoreKey.ValueOfTitle,
                      cart.meta.productCode,
                      cart.meta.articleCode,
                      true,
                    )
                  }
                }

                if (showDelivery) {
                  if (showDeliveryCtr && submitCtrDeliveryPoint) {
                    const resultDeliveryPoint = await submitCtrDeliveryPoint()
                    if (resultDeliveryPoint.isErr) {
                      return Result.err(resultDeliveryPoint.error)
                    }

                    dataStore[DataStoreKey.DeliveryPoint] = {
                      [ProductCode.CARTE_TICKET_RESTAURANT]: {
                        id: resultDeliveryPoint.value.id,
                        isMulti: resultDeliveryPoint.value.isMulti,
                        reference: !resultDeliveryPoint.value.isMulti ? resultDeliveryPoint.value.reference : null,
                        type: resultDeliveryPoint.value.type,
                        save: saveDefaultParams.value,
                      },
                    }
                  }

                  if (submitTrDeliveryPoint) {
                    const resultDeliveryPointTr = await submitTrDeliveryPoint()
                    if (resultDeliveryPointTr.isErr) {
                      return Result.err(resultDeliveryPointTr.error)
                    }
                    dataStore[DataStoreKey.DeliveryPoint] = {
                      ...dataStore[DataStoreKey.DeliveryPoint],
                      [ProductCode.TICKET_RESTAURANT]: {
                        id: resultDeliveryPointTr.value.id,
                        isMulti: resultDeliveryPointTr.value.isMulti,
                        reference: !resultDeliveryPointTr.value.isMulti ? resultDeliveryPointTr.value.reference : null,
                        type: resultDeliveryPointTr.value.type,
                        save: saveDefaultParams.value,
                      },
                    }
                  }

                  if (saveDefaultParams.value) {
                    await apiV4.dataStore.putDataStoreByProduct(
                      DataStoreKey.DeliveryPoint,
                      cart.meta.productCode,
                      cart.meta.articleCode,
                      dataStore[DataStoreKey.DeliveryPoint],
                      true,
                    )
                  } else {
                    await apiV4.dataStore.deleteDataStoreByProduct(
                      DataStoreKey.DeliveryPoint,
                      cart.meta.productCode,
                      cart.meta.articleCode,
                      true,
                    )
                  }
                }

                const cartStore = useStore()
                await cartStore.edit({
                  localId: cart.localId,
                  meta: {
                    dataStore,
                  },
                })

                const drawerStore = useDrawerStore()
                drawerStore.pop(moduleMixedConfig.id)
                return Result.ok(true)
              },
            }),
        }
      },
    }
  },
}

export default cartSetting
