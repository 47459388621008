import type { Ref } from 'vue'
import type { IResponse } from 'swrv/dist/types'
import type { Result } from '@badrap/result'
import type { Validation, ValidationArgs } from '@vuelidate/core'
import type { MaybeRef } from '@vueuse/core'
import type { Action } from '@/types/Action'
import { Block, type StepConfig, type StepWorkflow } from '@/store/cart/model'
import type { Pagination } from '@/services/common/types'
import type { CartItem } from '@/services/carts/types'
import type { PricingData } from '@/types/Price'
import type { PaginationData } from '@/composables/usePagination'
import type { Export } from '@/types/Filters'
import type { SelectOption } from '@/types/SelectOption'
import type { Table } from '@/types/Table'

export const stepConfig: StepConfig = {
  id: 'basket',
  path: '',
  name: 'Votre commande',
  blocks: {
    header: Block.Fixed,
  },
}

export interface ContentParams {
  page?: number
  filter?: string | null
  sort?: string
}

export interface CommonBasketWorkflow extends StepWorkflow {
  hasImport: boolean
  hasAdd: boolean
  hasDelete: boolean
  hasExport: boolean
  hasSearch: boolean
  hasBeneficiaryFilter: boolean
  beneficiaryOption: Ref<SelectOption | undefined>
  addAction?: MaybeRef<Action | null>
  importAction?: Action | null
  reimportAction?: Action | null
  emptyDescription: string
  table: Ref<Table>
  paginationData: PaginationData<CartItem>
}

export interface BasketWorkflow extends CommonBasketWorkflow {
  export?: Export
  pricings: IResponse<PricingData>[]
  response: IResponse<Pagination<CartItem>>
  settingsAction: Action
  showSettings: boolean
  showActions: MaybeRef<boolean>
  actionsAction?: Action
  useItemWorkflow: (item: CartItem) => BasketItemWorkflow
}

export type CartItemForm = {
  quantity: number
}

export interface BasketItemWorkflow {
  item: Ref<CartItem>
  state: Ref<CartItemForm>
  v$: Ref<Validation<ValidationArgs<CartItemForm>, CartItemForm>>
  editItem: () => Promise<Result<CartItem>>
  deleteAction: Action
  updateAction: (item: CartItem) => Action
}

export interface UnrefBasketItemWorkflow {
  item: CartItem
  state: CartItemForm
  v$: Validation<ValidationArgs<CartItemForm>, CartItemForm>
  editItem: () => Promise<Result<CartItem>>
  deleteAction: Action
  updateAction: (item: CartItem) => Action
}
