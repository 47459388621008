import univers from '@/assets/constantes/univers.json'
import habilitations from '@/variables/habilitations.json'
import generateOptionsConfig from '@/views/Commande/tunnels/Options/config'
import { VALIDATION_NAMES } from '@/views/Commande/tunnels/Options/rules'
import generateItemConfig from '@/views/Commande/tunnels/OrderView/config'
import { VALIDATION_ORDER_NAMES } from '@/views/Commande/tunnels/OrderView/rules'

const TR_NOMINATIF_OPTIONS = generateOptionsConfig({
  validations: [VALIDATION_NAMES.VF, VALIDATION_NAMES.PL],
  isImportTunnel: true,
  nextAbilities: [habilitations.TUNNEL.CTR_IMPORT, habilitations.TUNNEL.CTR_SAISIE_WEB],
  universName: univers.TR.UNIVERS.name,
  choices: {
    label: 'Saisie manuelle ou Import de fichier ?',
    items: [
      {
        abilities: [habilitations.TUNNEL.CTR_SAISIE_WEB, `${habilitations.TUNNEL.CTR_IMPORT}!`],
        imgSrc: {
          src: 'Documents_Espace_Client/Commande/popin-tunnel-commande/popin-saisie-manuelle.png',
          srcset: {
            '2x': 'Documents_Espace_Client/Commande/popin-tunnel-commande/popin-saisie-manuelle@2x.png',
            '3x': 'Documents_Espace_Client/Commande/popin-tunnel-commande/popin-saisie-manuelle@3x.png',
          },
          alt: 'Commande saisie manuelle',
          fromClick: true,
        },
        disabled: false,
        tooltipMessage: null,
        label: 'Commander en saisie manuelle',
        isImport: false,
        id: 'button-order-SW',
      },
      {
        abilities: [habilitations.TUNNEL.CTR_IMPORT, `${habilitations.TUNNEL.CTR_SAISIE_WEB}!`],
        imgSrc: {
          src: 'Documents_Espace_Client/Commande/popin-tunnel-commande/popin-import-de-fichier.png',
          srcset: {
            '2x': 'Documents_Espace_Client/Commande/popin-tunnel-commande/popin-import-de-fichier@2x.png',
            '3x': 'Documents_Espace_Client/Commande/popin-tunnel-commande/popin-import-de-fichier@3x.png',
          },
          alt: 'Commande import de fichier',
          fromClick: true,
        },
        disabled: false,
        tooltipMessage: null,
        label: 'Commander en import de fichier',
        isImport: true,
        id: 'button-order-import',
      },
    ],
  },
})

const TR_NO_NOMINATIF = generateItemConfig({
  validations: [
    VALIDATION_ORDER_NAMES.AMOUNT,
    VALIDATION_ORDER_NAMES.FORMAT,
    VALIDATION_ORDER_NAMES.VF,
    VALIDATION_ORDER_NAMES.PL,
  ],
  universName: univers.TR.UNIVERS.name,
})

const KADEOS_PAPER_NO_NOMINATIF = generateItemConfig({
  validations: [
    VALIDATION_ORDER_NAMES.AMOUNT,
    VALIDATION_ORDER_NAMES.FORMAT,
    VALIDATION_ORDER_NAMES.EVENT,
    VALIDATION_ORDER_NAMES.PL,
    VALIDATION_ORDER_NAMES.MESSAGE,
  ],
  universName: univers.TK.UNIVERS.name,
})

const KADEOS_PAPER_CULTURE_NO_NOMINATIF = generateItemConfig({
  validations: [
    VALIDATION_ORDER_NAMES.AMOUNT,
    VALIDATION_ORDER_NAMES.FORMAT,
    VALIDATION_ORDER_NAMES.PL,
    VALIDATION_ORDER_NAMES.MESSAGE,
  ],
  universName: univers.TK.UNIVERS.name,
})

const KADEOS_CARD_NO_NOMINATIF = generateItemConfig({
  validations: [
    VALIDATION_ORDER_NAMES.AMOUNT,
    VALIDATION_ORDER_NAMES.EVENT,
    VALIDATION_ORDER_NAMES.PL,
    VALIDATION_ORDER_NAMES.MESSAGE,
  ],
  universName: univers.TK.UNIVERS.name,
})

const KADEOS_CULTURE_CARD_NO_NOMINATIF = generateItemConfig({
  validations: [VALIDATION_ORDER_NAMES.AMOUNT, VALIDATION_ORDER_NAMES.PL, VALIDATION_ORDER_NAMES.MESSAGE],
  universName: univers.TK.UNIVERS.name,
})

export {
  KADEOS_PAPER_NO_NOMINATIF,
  KADEOS_CULTURE_CARD_NO_NOMINATIF,
  KADEOS_PAPER_CULTURE_NO_NOMINATIF,
  KADEOS_CARD_NO_NOMINATIF,
  TR_NOMINATIF_OPTIONS,
  TR_NO_NOMINATIF,
}
