import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { v4 as uuid4 } from 'uuid'
import { Result } from '@badrap/result'
import type { AuthnSession } from './model'
import { accessor } from '@/store'
import apiV3 from '@/services/apiV3'
import { ApiAuthnError, ApiAuthnErrorType } from '@/services/authn/errors'
import type { FactorType } from '@/services/auth/types'
import { formatTimeRemaining } from '@/utils/timeRemaining'

const AUTHN_DEVICE_ID = 'authn-device-id'

export const useStore = defineStore('authn', () => {
  let deviceId = localStorage.getItem(AUTHN_DEVICE_ID)

  if (!deviceId) {
    deviceId = uuid4()
    localStorage.setItem(AUTHN_DEVICE_ID, deviceId)
  }

  const session = ref<AuthnSession | null>(null)

  const canSendNewCodeIn = ref(0)
  const canSendNewCodeInFormatted = computed(() => formatTimeRemaining(canSendNewCodeIn.value))
  const canSendNewCode = computed(() => canSendNewCodeIn.value === 0)

  const nextAcceptableSentDate = ref<Date | null>(null)
  const remainingSentAttempts = ref(0)
  const codeSentTo = ref<string | null>(null)

  const interval = ref<ReturnType<typeof setTimeout> | null>(null)

  watch(nextAcceptableSentDate, (value) => {
    const now = new Date()

    if (interval.value) {
      clearInterval(interval.value)
      interval.value = null
    }

    if (value === null || value <= now) {
      canSendNewCodeIn.value = 0
    } else {
      const computeDiff = () => {
        const diff = Math.round((value.getTime() - new Date().getTime()) / 1000)

        if (diff <= 0) {
          canSendNewCodeIn.value = 0
        } else {
          canSendNewCodeIn.value = diff
        }
      }

      interval.value = setInterval(() => {
        computeDiff()

        if (canSendNewCodeIn.value <= 0 && interval.value) {
          clearInterval(interval.value)
          interval.value = null
        }
      }, 1000)

      computeDiff()
    }
  })

  const createSession = (id: string, email: string, factorType: FactorType, canBypassFactorValidationUntil: string) => {
    const canBypassFactorValidationDate = new Date(canBypassFactorValidationUntil)
    const canBypassFactorValidation = canBypassFactorValidationDate.getTime() > new Date().getTime()

    session.value = { id, email, factorType, canBypassFactorValidation, canBypassFactorValidationDate }
  }

  const clearSession = () => {
    session.value = null
    nextAcceptableSentDate.value = null
    remainingSentAttempts.value = 0
    codeSentTo.value = null

    if (interval.value) {
      clearInterval(interval.value)
      interval.value = null
    }
  }

  const requestCode = async () => {
    if (!canSendNewCode.value || session.value == null) {
      return
    }

    const result = await apiV3.authn.sessionSents(session.value.id)

    if (result.isOk) {
      nextAcceptableSentDate.value = new Date(result.value.NextAcceptableSentDate)
      remainingSentAttempts.value = result.value.RemainingSentAttempts
      codeSentTo.value = result.value.SentTo
    } else {
      throw result.error
    }
  }

  const validateCode = async (
    code: string,
    saveDevice: boolean,
    bypassFactorValidation = false,
  ): Promise<Result<true, ApiAuthnError>> => {
    if (session.value == null || (bypassFactorValidation && !session.value.canBypassFactorValidation) || !deviceId) {
      return Result.err()
    }

    const result = await apiV3.authn.sessionCode(code, session.value.id, saveDevice, bypassFactorValidation)

    if (result.isOk) {
      accessor.auth.setToken({
        accessToken: result.value.access_token,
        refreshToken: result.value.refresh_token,
      })

      const sessionResult = await accessor.session.fetchSession()

      if (sessionResult.isOk) {
        clearSession()
        return Result.ok(true)
      } else {
        await accessor.auth.logout({ redirect: false })
        return Result.err(new ApiAuthnError(ApiAuthnErrorType.UNKNOWN, sessionResult.error))
      }
    } else {
      return Result.err(result.error)
    }
  }

  return {
    deviceId,
    session,
    canSendNewCode,
    canSendNewCodeIn,
    canSendNewCodeInFormatted,
    nextAcceptableSentDate,
    codeSentTo,
    interval,
    createSession,
    clearSession,
    requestCode,
    validateCode,
  }
})
