import type { CartItem, Composition } from '@/services/carts/types'
import { ProductCode } from '@/variables/ProductCode'

export default (items: CartItem[]) => {
  const fetchCompo = (compositions: Composition[], productCode: ProductCode) => {
    return compositions
      .filter((composition) => composition.mixedComposition?.productCode === productCode)
      .reduce<{
        quantity: number
        total: number
        deliveryPoint: string | null
        distributionPoint: string | null
      }>(
        (r, composition) => {
          r.quantity += composition.quantity
          r.total += composition.titleValue
          r.deliveryPoint = composition.mixedComposition?.deliveryPoint ?? null
          r.distributionPoint = composition.mixedComposition?.distributionPoint ?? null

          return r
        },
        { quantity: 0, total: 0, deliveryPoint: null, distributionPoint: null },
      )
  }

  return (
    items.map((item) => {
      const ctrComposition = fetchCompo(item.compositions, ProductCode.CARTE_TICKET_RESTAURANT)
      const trComposition = fetchCompo(item.compositions, ProductCode.TICKET_RESTAURANT)

      return {
        ['table.headers.matricule']: item.beneficiary?.registrationNumber ?? null,
        ['table.headers.lastname']: item.beneficiary?.name ?? null,
        ['table.headers.firstname']: item.beneficiary?.firstName ?? null,
        ['table.headers.new']: item.compositions.find((conposition) => conposition.isNewBeneficiary === true)
          ? 'Oui'
          : null,
        ['table.headers.quantityCtr']: ctrComposition.quantity !== 0 ? ctrComposition.quantity : '0',
        ['table.headers.valueOfTitleCtr']: ctrComposition.total,
        ['table.headers.deliveryCtr']: ctrComposition.deliveryPoint,
        ['table.headers.distributionCtr']: ctrComposition.distributionPoint,
        ['table.headers.quantityTr']: trComposition.quantity !== 0 ? trComposition.quantity : '0',
        ['table.headers.valueOfTitleTr']: trComposition.total,
        ['table.headers.deliveryTr']: trComposition.deliveryPoint,
        ['table.headers.distributionTr']: trComposition.distributionPoint,
      }
    }) ?? []
  )
}
