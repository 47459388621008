import type { AsyncComponent } from 'vue'
import type { FilteredRequest, PaginatedRequest, SortRequest } from '../types'
import type { Beneficiary } from '../beneficiaries/types'
import type { DeliveryPoint } from '../deliveryPoints/types'
import type { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'
import type { Address } from '@/types/Address'

export enum FormatSaisie {
  Saisie,
  Import,
}

export interface ConfigurationTunnelRequest {
  defaultDeliveryPointRef: string
  defaultTitleValue: number | null
  defaultEmployersContribution: number | null
  isMultiPL: boolean
  isNominatif: boolean
  formatSaisie: FormatSaisie
  defaultEvent: number | null
}

export interface ConfigurationTunnel extends ConfigurationTunnelRequest {
  isMultiLoading: boolean
}

export enum CartStatus {
  Opened = 0,
  Closed,
  Cancelled,
}

export enum CartOrigin {
  None = 0,
  EmailAuto = 5,
  EmailManuel = 19,
  Ftp = 23,
  EC = 29,
  VEL = 30,
  IntravelFax = 34,
  IntravelCourrier = 35,
  IntravelEmail = 36,
  EspaceClientBenef = 54,
}

export interface CartV1 {
  id: number
  status: CartStatus
  referenceCommande: string
  preorderRedcartId: number
  productCode: number
  articleCode: number | null
  divisionCode: number
  creatorEmail: string
  isXSell: boolean
  configurationTunnel: ConfigurationTunnel
  creationDate: string | null
  origin: CartOrigin
  hadCommercialConditions: boolean
  deliveryDate: string | null
  isARenewal: boolean
  paymentMean: PaymentMeanV1 | null
  pricing: Pricing | null
}

export interface FetchCartItemsParams extends SortRequest, PaginatedRequest, FilteredRequest {
  isNewBeneficiary?: boolean
  beneficiaryIdFilter?: number
  isWithoutEmailBeneficiary?: boolean
}

export interface CreateCartRequestV1 {
  productCode: ProductCode
  articleCode: ArticleCode | null
  configurationTunnel: ConfigurationTunnelRequest
}

export enum CartItemType {
  Booklet = 'Booklet',
  Board = 'Board',
  RechargeableCard = 'RechargeableCard',
  Card = 'Card',
  Dematerialized = 'Dematerialized',
  Bulk = 'Bulk',
  Virtual = 'Virtual',
  NoConditoningMode = 'NoConditoningMode',
}

export interface CreateTitleCompositionRequest {
  quantity: number
  titleValue: number | null
  employersContribution: number | null
  facturationPoint: string
  source: string
}

export interface CartItemBeneficiaryV1 extends Beneficiary {
  email: string
}

export interface ItemMessage {
  line1: string
  line2: string
  line3: string
}

export interface CreateCartItemRequestV1 {
  itemType: CartItemType
  deliveryPointReference: string
  distributionPointReference: string
  packagingQuantity: number
  pocketsQuantity: number | null
  compositions: CreateTitleCompositionRequest[]
  beneficiary: CartItemBeneficiaryV1
  message: ItemMessage
  eventCode: number | null
  shouldBeDecomposed: boolean
}

export enum BeneficiaryImportStatus {
  All = 0,
  New,
  Existing,
  Conflicting,
  SolvedManually,
}

export interface BeneficiariesImportResultRequest extends SortRequest, PaginatedRequest, FilteredRequest {
  beneficiaryStatus: BeneficiaryImportStatus
}

export enum BeneficiaryImportPatchStatus {
  New = 1,
  Duplicate,
}

export interface PatchImportBeneficiary {
  status: BeneficiaryImportPatchStatus
  duplicateId?: number
}

export enum PriceKey {
  orderedAmount = 'orderedAmount',
  administrativeFees = 'administrativeFees',
  cardsCreationFees = 'cardsCreationFees',
  shippingFees = 'shippingFees',
  otherFees = 'otherFees',
  total = 'total',
  vat = 'vat',
  totalWithVAT = 'totalWithVAT',
  discountVE = 'discountVE',
  discount = 'discount',
}

export type Pricing = Record<PriceKey, number>

export type PricingSummary = {
  total: number
}

export interface DeliveryDatesEstimation {
  earliestDeliveryDate: string
  futureDeliveryDate: string
  latestDeliveryDate: string
}

export interface DeliveryInformations {
  billingAddress: Address | null
  deliveryPoint: DeliveryPoint | null
  datesEstimation: DeliveryDatesEstimation
}

export enum PaymentMeanV1 {
  Unknown = 0,
  Virement,
  Cheque,
  Especes,
  PrelevementAutomatique,
  VirementImmediat,
  Echange,
  CarteBancaire,
}

export enum PaymentMean {
  Unknown = 'Unknown',
  Virement = 'Virement',
  Cheque = 'Cheque',
  Especes = 'Especes',
  PrelevementAutomatique = 'PrelevementAutomatique',
  VirementImmediat = 'VirementImmediat',
  Echange = 'Echange',
  CarteBancaire = 'CarteBancaire',
}

export type PaymentMeanComponent = {
  type: PaymentMean
  component: AsyncComponent
}

export const paymentMeanComponents: PaymentMeanComponent[] = [
  { type: PaymentMean.PrelevementAutomatique, component: () => import('@/components/PaymentMeans/radios/Debit.vue') },
  {
    type: PaymentMean.VirementImmediat,
    component: () => import('@/components/PaymentMeans/radios/ImmediateTransfer.vue'),
  },
  { type: PaymentMean.Virement, component: () => import('@/components/PaymentMeans/radios/Transfer.vue') },
  { type: PaymentMean.CarteBancaire, component: () => import('@/components/PaymentMeans/radios/CreditCard.vue') },
  { type: PaymentMean.Cheque, component: () => import('@/components/PaymentMeans/radios/Check.vue') },
]

export const v1PaymentMeanToV2: Record<PaymentMeanV1, PaymentMean> = {
  [PaymentMeanV1.Unknown]: PaymentMean.Unknown,
  [PaymentMeanV1.Virement]: PaymentMean.Virement,
  [PaymentMeanV1.Cheque]: PaymentMean.Cheque,
  [PaymentMeanV1.Especes]: PaymentMean.Especes,
  [PaymentMeanV1.PrelevementAutomatique]: PaymentMean.PrelevementAutomatique,
  [PaymentMeanV1.VirementImmediat]: PaymentMean.VirementImmediat,
  [PaymentMeanV1.Echange]: PaymentMean.Echange,
  [PaymentMeanV1.CarteBancaire]: PaymentMean.CarteBancaire,
}

export const v2PaymentMeanToV1: Record<PaymentMean, PaymentMeanV1> = {
  [PaymentMean.Unknown]: PaymentMeanV1.Unknown,
  [PaymentMean.Virement]: PaymentMeanV1.Virement,
  [PaymentMean.Cheque]: PaymentMeanV1.Cheque,
  [PaymentMean.Especes]: PaymentMeanV1.Especes,
  [PaymentMean.PrelevementAutomatique]: PaymentMeanV1.PrelevementAutomatique,
  [PaymentMean.VirementImmediat]: PaymentMeanV1.VirementImmediat,
  [PaymentMean.Echange]: PaymentMeanV1.Echange,
  [PaymentMean.CarteBancaire]: PaymentMeanV1.CarteBancaire,
}

export type PaymentRequestBody = {
  paymentMean: PaymentMean
  successRedirectUrl: string
  cancelRedirectUrl: string
  paymentToken?: string
}

export type PaymentsResponse = {
  meta: {
    termUrl: string
    redirectionData: string
    paReqMessage: string
    paymentId: number
    key: string
  }
  paymentMean: PaymentMean
  redirectionUrl: string
}

export interface CreateCartRequest {
  productCode: ProductCode
  articleCode?: ArticleCode | null
  discountCode?: string
  options?: {
    isNominative: boolean
    isMixed: boolean
    isMultiLoading: boolean
    isPlasticless: boolean
    isHomeDelivery: boolean | null
  }
}

export interface PatchCartRequest {
  reference: string | null
  discountCode: string | null
  deliveryDate: string | null
  isHomeDelivery: boolean | null
}

export interface Message {
  line1?: string | null
  line2?: string | null
  line3?: string | null
}

export interface CommonTitleCompositionRequest {
  quantity: number | null
  titleValue: number | null
  employersContribution: number | null
}

export interface TitleComposition extends CommonTitleCompositionRequest {
  facturationPoint?: string | null
  source?: string | null
}

export interface CommonCartItemRequest {
  deliveryPointReference: string | null
  distributionPointReference: string | null
  packagingQuantity: number | null
  compositions: CommonTitleCompositionRequest[]
}

export interface CartItemRequest extends CommonCartItemRequest {
  itemType: CartItemType
  deliveryPointReference: string | null
  distributionPointReference: string | null
  packagingQuantity: number
  pocketsQuantity?: number | null
  compositions: TitleComposition[]
  beneficiary?: CartItemBeneficiaryRequest | null
  message?: Message | null
  eventCode?: number | null
}

export interface CartItemBeneficiaryRequest {
  id: number
}

export interface CommonComposition {
  quantity: number
  titleValue: number
  source: string | null // financeur iterim

  // TR / CTR / N /
  employersContribution: number | null
  isNewBeneficiary: boolean

  // Mixte:
  mixedComposition: {
    productCode: ProductCode
    deliveryPoint: string | null
    distributionPoint: string | null
  } | null

  // Iterim
  facturationPoint?: string | null
}

export interface Composition extends CommonComposition {
  id: number
}

export interface CartItem {
  id: number
  itemType: CartItemType
  eventCode: number | null
  deliveryPointReference: string | null
  distributionPointReference: string | null
  packagingQuantity: number | null
  pocketsQuantity?: number // pochette
  beneficiary: Beneficiary | null
  compositions: Composition[]
  totalTitleQuantities: number
  totalQuantities: number
  totalTitleAmount: number
  totalAmount: number
  description: string | null // decomposition (ex: 2 x 3$ + 3 x 5$)
  message: Message // KS
  rowspan?: number // multiloading
}

export function fetchCartItemType(productCode: ProductCode, isPlasticless = false): CartItemType {
  if (productCode === ProductCode.CARTE_TICKET_RESTAURANT && isPlasticless) {
    return CartItemType.Virtual
  }

  switch (productCode) {
    case ProductCode.TICKET_RESTAURANT:
      return CartItemType.Booklet
    case ProductCode.TICKET_RESTAURANT_BENEVOLES_VOLONTAIRE:
      return CartItemType.Booklet
    case ProductCode.KADEOS_CONNECT:
      return CartItemType.Dematerialized
    case ProductCode.CARTE_TICKET_RESTAURANT:
      return CartItemType.RechargeableCard
  }

  return CartItemType.NoConditoningMode
}
