import type { Ref } from 'vue'
import type { IResponse } from 'swrv/dist/types'
import type { Result } from '@badrap/result'
import type { DeliveryDatesEstimation, PaymentMean } from '@/services/carts/types'
import { Block, type StepConfig, type StepWorkflow } from '@/store/cart/model'
import type { Action } from '@/types/Action'
import type { PricingData } from '@/types/Price'
import type { ProductCode } from '@/variables/ProductCode'
import type { DisabledDates } from '@/types/Date'
import type { ArticleCode } from '@/variables/ArticleCode'
import type { PricingDetail } from '@/types/PricingDetail'
import type { DeliveryChoiceSelectType } from '@/types/CartDelivery'

export const stepConfig: StepConfig = {
  id: 'payment',
  path: 'paiement',
  name: 'Paiement',
  blocks: {
    header: Block.Fixed,
  },
}

export interface Content {
  title: string
  description: string
}

export interface DeliveryProp {
  title: string
  description?: string
  right: Content | null
  type: 'default' | 'dematerialized'
  canChangedDate: boolean
  choiceType: Ref<DeliveryChoiceSelectType>
  deliveryDate: Ref<Date | null>
  deliveryEstimation: DeliveryDatesEstimation
  productCode: ProductCode
  articleCode: ArticleCode | null
  disabledDates?: DisabledDates | null
  submit: () => Promise<Result<boolean>>
}

export interface UseDeliveryConfig {
  title: string
  description?: string
  type: 'default' | 'dematerialized'
  canChangedDate: boolean
  deliveryDate: string | null
  productCode: ProductCode
  articleCode: ArticleCode | null
  disabledDates?: DisabledDates | null
}

export interface BillingProp {
  title: string
  description: string
  right: Content | null
  productCode: ProductCode
  articleCode: ArticleCode | null
}

export type PaymentMeanProps = {
  paymentMean: PaymentMean | null
  paymentMeans: PaymentMean[]
}

export type LightPrice = {
  value: number
  shipping: number
  tax: number
}

export interface CommonPaymentWorkflow extends StepWorkflow {
  showProduct: boolean
  showDownloadSummary: boolean
  paymentMeanProps: IResponse<PaymentMeanProps>
  terms: Ref<boolean>
  pricings: IResponse<PricingData>[]
  discountCode: Ref<string | null>
  discountAction: Action
  discountModalAction: Action | null
  downloadSummaryAction: Action
  pricingDetails: PricingDetail[]
  payAction: (submits: (() => Promise<Result<boolean>>)[], fetchPaymentToken: () => Promise<Result<string>>) => Action
}

export interface PaymentWorkflow extends CommonPaymentWorkflow {
  dataProps: { deliveries: DeliveryProp[]; billing: BillingProp }[]
  missingEmailsAction?: Action
  showMissingEmails: boolean
}
