import simulator from './fr/simulator'
import csv from './fr/csv'
import table from './fr/table'
import cart from './fr/cart'
import paymentMeans from './fr/paymentMeans'
import managers from './fr/managers'
import valueOfTitle from './fr/valueOfTitle'
import mfa from './fr/mfa'
import profile from './fr/profile'
import universe from './fr/universe'
import clipboard from './fr/clipboard'
import invoices from './fr/invoices'
import deliveryPoints from './fr/deliveryPoints'
import orders from './fr/orders'
import beneficiaries from './fr/beneficiaries'
import benefitsCalculator from './fr/benefitsCalculator'

export default {
  ...simulator,
  ...csv,
  ...table,
  ...cart,
  ...paymentMeans,
  ...managers,
  ...valueOfTitle,
  ...profile,
  ...universe,
  ...clipboard,
  ...invoices,
  ...deliveryPoints,
  ...orders,
  ...beneficiaries,
  ...benefitsCalculator,
  infoBlock: {
    billingPoint: {
      title: 'Adresse de facturation',
    },
    deliveryPoint: {
      title: 'Adresse de livraison',
      description: 'Les points de livraison sont personnalisés en fonction des bénéficiaires.',
    },
    plasticless: {
      title: 'Demandes de cartes physiques',
      description:
        "Vos bénéficiaires pourront demander la création et l'envoi d'une carte physique depuis l'application MyEdenred",
    },
  },
  popup: {
    nominativeAndNot: {
      title: 'Anonyme ou nominatif ?',
      anonymous: 'Anonyme',
      nominative: 'Nominatif',
      paperDescription: 'Le nom et le prénom de vos collaborateurs seront inscrits sur chacun des titres.',
      cardDescription: 'Personnalisez vos cartes avec le nom de vos collaborateurs.',
      order: 'Commander',
    },
    changeAccount: {
      title: 'Changer de compte client',
      table: {
        clientAccount: 'Compte client',
        clientNumber: '	N° de client',
        associatedAccount: 'Compte associé',
      },
      connectedLabel: 'Connecté',
      associate: {
        successOn: 'Votre compte n°{division} a bien été associé.',
        successOff: 'Votre compte n°{division} a bien été dissocié.',
        error: {
          atLeastOne: 'Vous ne pouvez pas dissocier votre unique compte associé.',
          panda: "Impossible d'associer votre compte à cette division pour le moment.",
          unknown: "Une erreur est survenue lors de l'association du compte n° {division}.",
        },
      },
      defaultAccountTooltip: 'À la connexion, vous arrivez par défaut sur le compte principal',
    },
  },
  home: {
    page: 'Accueil',
    newOrder: 'Nouvelle commande',
    order: 'Commander',
    orderAndRecharge: 'Commander / Recharger',
    lastOrder: 'Dernière commande',
    noLastOrder: "Vous n'avez pas encore passé de commande",
    seeLastOrder: 'Voir ma commande',
    renew: 'Renouveler ma commande',
    rapidAction: 'Actions rapides',
    xsellCta: 'Être recontacté',
    freeQuote: 'Devis gratuit',
    discoverCta: 'Découvrir',
    discover: {
      title: 'Découvrez tous nos produits',
      mobileTitle: 'Vous serez aussi intéressés par',
      mobileContent:
        "Ticket Restaurant® vous intéresse ? Nous développons toute une gamme de solutions pour vous permettre d'améliorer l'expérience de vos collaborateurs&nbsp;:",
      cta: 'Découvrir',
    },
    xsell: {
      title: 'Me faire rappeler',
      successMessage: 'Votre demande est validée, nous vous contacterons rapidement',
      errorMessage: "Une erreur est survenue lors de l'envoi du formulaire",
      errors: {
        companyMemberCount: {
          required: "L'effectif est requis",
        },
        companyType: {
          required: "Le type de l'entreprise est requis",
        },
        lastName: {
          required: 'Le nom est requis',
        },
        firstName: {
          required: 'Le prénom est requis',
        },
        email: {
          required: "L'email est requis",
          email: 'Cet email a un format invalide',
        },
        phone: {
          required: 'Le numéro de téléphone est requis',
          minLength: 'Le numéro de téléphone doit contenir au moins 10 chiffres',
          maxLength: 'Le numéro de téléphone ne peut contenir plus de 10 chiffres',
        },
        service: {
          required: 'Le service est requis',
        },
        function: {
          required: 'La fonction est requise',
        },
      },
    },
    error: {
      title: 'Une erreur est survenue',
      description: "Une erreur est survenue lors du chargement de l'accueil",
    },
  },
  notifications: {
    title: 'Notifications',
    emptyMessage: 'Vous n’avez aucune notification',
  },
  ...mfa,
  alert: {
    more: 'En savoir plus',
  },
  advancedSettings: {
    title: 'Paramètres avancés',
  },
  vintageReport: {
    title: 'Transfert de solde automatique',
    description:
      'À la fin de chaque millésime, les soldes restants sur les comptes de vos collaborateurs sont automatiquement transférés. Si vous désactivez cette option, chacun de vos collaborateurs devra demander le transfert de son solde depuis l’application MyEdenred.',
    tooltip: 'La date limite pour effectuer votre choix pour le millésime {millesimeYear} est le {limitDate}',
    concernProduct: 'Produits concernés',
    enabled: {
      title: 'Transfert automatique activé',
      description: 'Le solde de vos collaborateurs sera automatiquement transféré',
    },
    disabled: {
      title: 'Transfert automatique désactivé',
      description: 'Vos collaborateurs devront demander le transfert de leur solde depuis l’application MyEdenred',
    },
    error: {
      title: 'Erreur',
      description:
        'Un probleme technique nous a empeché de sauvegarder votre choix. Merci de réessayer ulterieurement.',
    },
  },
  contractDisabled: {
    title: 'Contrat inactif',
    description:
      'Pas de contract inactif | Votre contrat {products} est inactif, merci de nous contacter via notre formulaire de contact | Vos contrats {products} et {lastProduct} sont inactifs, merci de nous contacter via notre formulaire de contact',
  },
  customImport: {
    title: 'Import personnalisé',
    description:
      'Vous pouvez établir une correspondance des en-têtes de votre propre fichier de commande avec celles attendues par notre système. Paramètrez une seule fois votre fichier : vos données seront automatiquement intégrées pour les commandes suivantes !',
    importYourFile: 'Importer votre fichier',
    associate: {
      title: 'Associer mes colonnes',
      description:
        'Faites correspondre vos en-têtes de colonnes avec celles d’Edenred. L’ordre des colonnes n’a pas d’importance pour votre commande, seul l’intitulé des en-têtes compte.',
      header: 'En-têtes Edenred',
      headerCustom: 'Vos en-têtes personnalisées',
      headerSm: 'Choisissez la correspondance',
      correspondence: 'Correspondance',
      byDefault: 'Par défaut',
    },
  },
  importFile: {
    drag: 'Glisser / déposer votre fichier ici ou',
    errorFile: 'Votre fichier “ {filename} “ comporte plusieurs erreurs',
    errorDuplicate:
      'Un doublon de salarié a été détecté dans votre fichier de commande. Merci de corriger votre fichier et de le ré-importer',
    errorImportAction: 'Ré-importer depuis votre ordinateur',
    errorReminder: 'Format accepté : CSV (séparateur point virgule)',
    importAction: 'Importer un fichier',
    reminder1: 'Les colonnes en plus sont ignorées',
    reminder2: 'Seul le format CSV est accepté (Pour PC : séparateur “ ; ”. Pour MAC UTF8 séparateur " ; ")',
    reminder3: 'Les colonnes en majuscule sont obligatoires',
    reminder4: 'Aucun champ ne doit contenir de point virgule',
    reimportAction: 'Ré-importer depuis votre ordinateur',
    error: {
      button: {
        valueOfTitleList: {
          title: 'Afficher la liste des valeurs du titre',
          label: 'Liste valeurs du titre',
        },
        deliveryPointsList: {
          title: 'Afficher la liste des points de livraison',
          label: 'Liste points de livraison',
        },
        distributionPointsList: {
          title: 'Afficher la liste des points de distribution',
          label: 'Liste points de distribution',
        },
      },
    },
  },
  multipleItems: '- | {items} | {items} et {lastItem}',
  delete: 'Supprimer',
  edit: 'Éditer',
  update: 'Modifier',
  add: 'Ajouter',
  added: 'Ajouté',
  back: 'Retour',
  cancel: 'Annuler',
  skip: 'Passer',
  save: 'Enregistrer',
  validate: 'Valider',
  create: 'Créer',
  close: 'Fermer',
  open: 'Ouvrir',
  birthday: 'Date de naissance',
  matricule: 'Matricule',
  countryCode: 'Indicatif pays',
  phoneNumber: 'Numéro de téléphone',
  retry: 'Réessayer',
  confirm: 'Confirmer',
  next: 'Suivant',
  configure: 'Paramétrer',
  share: 'Partager',
  download: 'Télécharger',
  search: 'Rechercher',
  state: 'État',
  status: 'Statut',
  product: 'Produit',
  billingPoint: 'Point de facturation',
  year: 'an',
  byYear: 'par an',
  mailto: 'Envoyer par e-mail',
  toActivate: 'À activer',
  toConfigure: 'À paramétrer',
  description: 'Description',
  enabled: 'Activé',
  disabled: 'Désactivé',
  notProvided: 'Non renseigné',
  new: 'Nouveau',
  button: {
    showDeliveryPoint: 'Voir les points de livraison',
    showDistributionPoint: 'Voir les points de distributions',
    showAmount: 'Voir les montants',
  },
  block: 'le bloc',
  Card: 'Cartes',
  Booklet: 'Carnets',
  Board: 'Tickets',
  Bulk: 'Tickets',
  errors: {
    export: {
      title: 'Export',
      description: 'Une erreur est survenue durant la génération de votre export',
    },
    unknown: {
      title: 'Une erreur est survenue',
      description:
        "Une erreur inconnue s'est produite, nous en avons été informés et corrigerons rapidement le problème",
    },
  },
  howTo: 'Comment faire',
  toDefine: '<span class="text-danger">À définir</span>',
  contactUs: 'Nous contacter',
  byEmail: 'Par email',
  atHome: 'Chez le bénéficiaire',
  atCompany: "À l'entreprise",
  juridicErrorField: 'Veuillez accepter les conditions',
}
