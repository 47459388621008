import type { IResponse } from 'swrv/dist/types'
import type { ComputedRef, Ref } from 'vue'
import type { Result } from '@badrap/result'
import type { Beneficiary } from '@/services/beneficiaries/types'
import { type ModuleConfig, ModuleType, type RemoteCart } from '@/store/cart/model'
import type { PaginationData } from '@/composables/usePagination'
import type { Pagination } from '@/services/common/types'
import type { Action } from '@/types/Action'
import type { Table } from '@/types/Table'
import type { UseBeneficiaryFormWorkflow } from '@/composables/useBeneficiaryFormWorkflow'
import type { CartItemRequest } from '@/services/carts/types'

export const moduleConfig: ModuleConfig = {
  id: 'add-beneficiaries',
  type: ModuleType.Drawer,
  component: () => import('@/components/Cart/Module/Beneficiary/index.vue'),
}

export type Tab = {
  id: string
  label: string
  icon: string
}

export interface AddBeneficiariesWorkflow {
  tabs: Tab[]
  tab: Ref<Tab>
  useAddTabWorkflow: () => AddTabWorkflow
  useCreateTabWorkflow: () => AddBeneficiaryItemWorkflow
}

export interface AddTabWorkflow {
  paginationData: PaginationData<Beneficiary>
  beneficiariesResponses: IResponse<{ paginateData: Pagination<Beneficiary>; workflows: BeneficiaryWorkflow[] }>
  table: Ref<Table>
  valueOfTitleAction: Action
  deliveryPointAction: Action | null
  addAllBeneficiariesAction: ComputedRef<Action | null>
}

export interface BeneficiaryWorkflow {
  beneficiary: Beneficiary
  add: Ref<boolean>
  quantity: Ref<number>
  addAction: Action
  fetchCartItemRequest: () => CartItemRequest | null
}

export interface ItemFormWorkflow {
  quantity: Ref<number>
  fetchCartItemRequest: (beneficiary: Beneficiary) => CartItemRequest
  submit: (beneficiary: Beneficiary) => Promise<Result<void>>
}

export type UseItemFormWorkflow = (cart: RemoteCart) => ItemFormWorkflow

export interface AddBeneficiaryItemWorkflow {
  title: string
  cancelAction: Action | null
  useBeneficiaryFormWorkflow: UseBeneficiaryFormWorkflow
  submitAction: (submitBenef: () => Promise<Result<Beneficiary>>) => Action
}
