import optionsFormats from '@/assets/constantes/optionsFormats'

export const VALIDATION_NAMES = {
  VF: 'facialValue',
  PL: 'deliveryPoint',
  EVENT: 'event',
  AMOUNT: 'amount',
}

const rules = {
  [VALIDATION_NAMES.EVENT]: ({ value, eventList }) => {
    if (!value || !eventList > 0) {
      return false
    }
    if (value.format === optionsFormats.MONO_EVENT) {
      return Number.isInteger(value?.event.value)
    }
    return value.format === optionsFormats.MULTI_EVENTS && !value.event
  },
  [VALIDATION_NAMES.AMOUNT]: ({ value }) => {
    if (!value) {
      return false
    }
    if (value.format === optionsFormats.MONO_AMOUNT) {
      return Number.isInteger(value?.amount)
    }
    return value.format === optionsFormats.MULTI_AMOUNT && !value.amount
  },
  [VALIDATION_NAMES.VF]: ({ value, facialValueChoices }) => {
    if (!value || !facialValueChoices || facialValueChoices === 0) {
      return false
    }
    if (value.format === optionsFormats.MONO_VF) {
      const hasValidValue = Number.isInteger(value?.facialValue?.value) && value?.facialValue?.value > 0
      const hasValidPpp =
        Number.isInteger(value?.facialValue?.employersContribution) && value?.facialValue?.employersContribution > 0
      return hasValidValue && hasValidPpp
    }
    return value.format === optionsFormats.MULTI_VF && !value.facialValue
  },
  [VALIDATION_NAMES.PL]: ({ value, plSitesChoices, plBenefChoices }) => {
    if (!value || (!plSitesChoices && !plBenefChoices)) {
      return false
    }

    if (
      (value.format === optionsFormats.MONO_CARRIER && plBenefChoices > 0) ||
      (value.format === optionsFormats.MONO_PL && plSitesChoices > 0)
    ) {
      return !!value.deliveryPointRef
    }

    if (
      (value.format === optionsFormats.MULTI_CARRIER && plBenefChoices > 0) ||
      (value.format === optionsFormats.MULTI_PL && plSitesChoices > 0)
    ) {
      return !value.deliveryPointRef
    }
    return false
  },
}

export function checkRule(validator, value) {
  return rules[validator] && rules[validator](value)
}
