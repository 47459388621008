import { Result } from '@badrap/result'
import type { Asset, ContentfulClientApi, Entry } from 'contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { INLINES } from '@contentful/rich-text-types'
import type { TypeCtaSkeleton, TypeOrderConfirmationCardSkeleton } from '../generated'
import { mapMediaToLocal } from '../types/CFMedia'
import type { CFOrderConfirmationCard } from '../types/CFOrderConfirmationCard'
import { mapCtaToLocal } from '../types/CFCta'

export function mapOrderConfirmationCardToLocal(
  item: Entry<TypeOrderConfirmationCardSkeleton, undefined, string>,
): CFOrderConfirmationCard {
  return {
    ...item.fields,
    description: documentToHtmlString(item.fields.description, {
      renderNode: {
        [INLINES.HYPERLINK]: (node, next) => {
          return `<a class="btn-link" target="_blank" href=${node.data.uri}>${next(node.content)}</a>`
        },
      },
    }),
    cta: mapCtaToLocal(item.fields.cta as Entry<TypeCtaSkeleton, undefined, string>),
    image: mapMediaToLocal(item.fields.image as Asset<undefined, string>),
  }
}

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchOrderConfirmationCard = async (id: string) => {
    try {
      const result = await contentfulInstance.getEntries<TypeOrderConfirmationCardSkeleton>({
        content_type: 'orderConfirmationCard',
        'fields.id': id,
      })

      if (result.items.length === 0) {
        return Result.err(new Error('OrderConfirmationCard not found'))
      }

      return Result.ok(mapOrderConfirmationCardToLocal(result.items[0]))
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  return {
    fetchOrderConfirmationCard,
  }
}
