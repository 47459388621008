import payment, {
  useBillingContent,
  useDelivery,
  commonPaymentWorkflow,
} from '@/store/cart/cartWorkflows/common/steps/payment'
import { usePricing } from '@/store/cart/cartWorkflows/common/usePricing'
import type { PaymentWorkflow } from '@/components/Cart/Step/Payment/workflow'
import type { StepBuilder } from '@/store/cart/model'
import type { DeliveryInformations } from '@/services/carts/types'

const step: StepBuilder<PaymentWorkflow> = {
  ...payment,
  useStep(
    cart,
    workflow,
    record: { deliveryInformations: Record<number, DeliveryInformations>; totalItems: number },
    useAction,
  ) {
    const useStep = payment.useStep(cart, workflow, record, useAction)
    return {
      ...useStep,
      useWorkflow() {
        const deliveryInformation = record.deliveryInformations[cart.remote.id]

        return {
          ...commonPaymentWorkflow(cart, workflow, record, [usePricing(cart)], useAction),
          showDownloadSummary: true,
          showMissingEmails: false,
          dataProps: [
            {
              deliveries: [
                useDelivery(cart.remote, deliveryInformation, {
                  title: 'cart.delivery.load',
                  canChangedDate: false,
                  type: 'dematerialized',
                  productCode: cart.remote.description.productCode,
                  articleCode: cart.remote.description.articleCode,
                  deliveryDate: cart.remote.paymentOptions.deliveryDate,
                  description: 'cart.delivery.kscDescription',
                }),
              ],

              billing: {
                title: 'cart.billing.title',
                description: 'cart.billing.description',
                right: useBillingContent(cart, deliveryInformation.billingAddress),
                productCode: cart.remote.description.productCode,
                articleCode: cart.remote.description.articleCode,
              },
            },
          ],
        }
      },
    }
  },
}

export default step
