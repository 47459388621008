import commonsCartWorkflow from '..'
import setting from '../../../../common/module/settings/CKSN'
import stepBasket from './steps/basket'
import stepImport from './steps/import'
import type { CartWorkflow, FrontCart } from '@/store/cart/model'
import { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'
import clearCart from '@/store/cart/cartWorkflows/common/module/clearCart'

const steps = commonsCartWorkflow.steps.map((step) => {
  if (step.config.id === stepBasket.config.id) {
    return stepBasket
  }

  return step
})

steps.push(stepImport)

const modules = [...commonsCartWorkflow.modules, clearCart, setting]

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  modules,
  isValid(cart: FrontCart) {
    return (
      cart.meta.isNominative === true &&
      cart.meta.productCode === ProductCode.CARTE_KADEOS &&
      (cart.meta.articleCode === ArticleCode.CARTE_KADEOS_ZENITH_CE ||
        cart.meta.articleCode === ArticleCode.CARTE_KADEOS_ZENITH_DE ||
        cart.meta.articleCode === ArticleCode.CARTE_KADEOS_CULTURE)
    )
  },
}

export default cartWorkflow
