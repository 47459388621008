import type { Ref } from 'vue'
import type { Result } from '@badrap/result'
import type { Validation, ValidationArgs } from '@vuelidate/core'
import type { UseDeliveryPointWorkflow, UseValueOfTitleWorkflow } from '../common/workflow'
import type { AmountData } from '../AddItem/workflow'
import { type ModuleConfig, ModuleType, type LocalDeliveryPoint } from '@/store/cart/model'
import type { Action } from '@/types/Action'
import type { ValueOfTitleData } from '@/types/ValueOfTitle'
import type { CFCartItemConfig } from '@/services/contentful/types/CFProduct'
import type { CFEvent } from '@/services/contentful/types/CFEvent'

export const moduleConfig: ModuleConfig = {
  id: 'import-options-drawer',
  type: ModuleType.Drawer,
  component: () => import('@/components/Cart/Module/Settings/TR.vue'),
}

export const moduleCTRVConfig: ModuleConfig = {
  id: 'import-options-drawer',
  type: ModuleType.Drawer,
  component: () => import('@/components/Cart/Module/Settings/CTRV.vue'),
}

export const moduleMixedConfig: ModuleConfig = {
  id: 'import-options-drawer',
  type: ModuleType.Drawer,
  component: () => import('@/components/Cart/Module/Settings/Mixed.vue'),
}

export const moduleKSCConfig: ModuleConfig = {
  id: 'import-options-drawer',
  type: ModuleType.Drawer,
  component: () => import('@/components/Cart/Module/Settings/KSC.vue'),
}

export const moduleCKSCConfig: ModuleConfig = {
  id: 'import-options-drawer',
  type: ModuleType.Drawer,
  component: () => import('@/components/Cart/Module/Settings/CKSN.vue'),
}

export interface CommonCartSettingsWorkflow {
  saveDefaultParams: Ref<boolean>
}

export interface TrRecord {
  showValueOfTitle: boolean
  showDelivery: boolean
}

export interface TrCartSettingsWorkflow extends CommonCartSettingsWorkflow, TrRecord {
  isMultiValueOfTitle: Ref<boolean>
  valueOfTitleProductImg: string
  deliveryProductImg: string
  deliveryTitle: string
  useValueOfTitleWorkflow: UseValueOfTitleWorkflow
  useDeliveryPointWorkflow: UseDeliveryPointWorkflow
  submitAction: (
    submitValueOfTitle?: () => Promise<Result<ValueOfTitleData>>,
    submitDeliveryPoint?: () => Result<LocalDeliveryPoint>,
  ) => Action
}

export interface CTRVCartSettingsWorkflow extends CommonCartSettingsWorkflow, TrRecord {
  isMultiValueOfTitle: Ref<boolean>
  productImg: string
  useValueOfTitleWorkflow: UseValueOfTitleWorkflow
  submitAction: (submitValueOfTitle?: () => Promise<Result<ValueOfTitleData>>) => Action
}

export interface MixedCartSettingsWorkflow extends CommonCartSettingsWorkflow, TrRecord {
  isMultiValueOfTitle: Ref<boolean>
  valueOfTitleProductImg: string
  showDeliveryCtr: boolean
  deliveryProductImgCtr: string
  deliveryProductImgTr: string
  deliveryTitleCtr: string
  deliveryTitleTr: string
  useValueOfTitleWorkflow: UseValueOfTitleWorkflow
  useCtrDeliveryPointWorkflow: UseDeliveryPointWorkflow
  useTrDeliveryPointWorkflow: UseDeliveryPointWorkflow
  submitAction: (
    submitValueOfTitle?: () => Promise<Result<ValueOfTitleData>>,
    submitTrDeliveryPoint?: () => Promise<Result<LocalDeliveryPoint>>,
    submitCtrDeliveryPoint?: () => Promise<Result<LocalDeliveryPoint>>,
  ) => Action
}

export interface KscImportOptionsState {
  isMultiAmount: boolean
  amount: AmountData | null
  event: CFEvent | null
}

export interface CksNImportOptionsState {
  isMultiAmount: boolean
  amount: AmountData | null
  isMultiEvent: boolean
  event: CFEvent | null
}

export interface KscRecord {
  events: CFEvent[]
  showEvent: boolean
  showAmount: boolean
}

export interface KscCartSettingsWorkflow extends CommonCartSettingsWorkflow, KscRecord {
  v$: Ref<Validation<ValidationArgs<KscImportOptionsState>, KscImportOptionsState>>
  cartItemConfig: CFCartItemConfig
  submitAction: Action
}

export interface CksNCartSettingsWorkflow extends CommonCartSettingsWorkflow, KscRecord {
  v$: Ref<Validation<ValidationArgs<CksNImportOptionsState>, CksNImportOptionsState>>
  cartItemConfig: CFCartItemConfig
  showDelivery: boolean
  deliveryProductImg: string
  deliveryTitle: string
  useDeliveryPointWorkflow: UseDeliveryPointWorkflow
  submitAction: (submitDeliveryPoint?: () => Promise<Result<LocalDeliveryPoint>>) => Action
}
