import axios from 'axios'
import authApi from './auth/authApi'
import authnApi from './authn/authnApi'
import sessionApi from './session/sessionApi'
import ordersHistoryApi from './ordersHistory'
import facialValuesApi from './facialValues/facialValuesApi'
import proxyfy from './utils/proxyfy'
import profileApi from './uTR/apis/profile/profileApi'
import managementApi from './management'
import paymentMeansApi from './uTR/apis/paymentMeansApi.js'
import billingPointsApi from './billingPoints'

const proxyfyAxios = proxyfy(axios)

export default {
  auth: authApi(proxyfyAxios),
  authn: authnApi(proxyfyAxios),
  billingPoints: billingPointsApi(proxyfyAxios),
  facialValues: facialValuesApi(proxyfyAxios),
  management: managementApi(proxyfyAxios),
  paymentMeans: paymentMeansApi(proxyfyAxios),
  profile: profileApi(proxyfyAxios),
  session: sessionApi(proxyfyAxios),
  ordersHistory: ordersHistoryApi(proxyfyAxios),
}
