import { Result } from '@badrap/result'
import { reactive, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers, requiredIf } from '@vuelidate/validators'
import { useDeliveryPointWorkflow } from '../../composables/useDeliveryPointWorkflow'
import {
  type CksNCartSettingsWorkflow,
  moduleCKSCConfig,
  type CksNImportOptionsState,
} from '@/components/Cart/Module/Settings/workflow'
import type { FrontCartDataStore, ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import { getErrorMessage } from '@/utils/vuelidate'
import { useI18n } from '@/composables/useI18n'
import { CartItemType } from '@/services/carts/types'
import { toPrice } from '@/utils/price'
import { useStore as useCartStore } from '@/store/cart/store'
import { DataStoreKey } from '@/services/dataStore/types'
import { useStore as useDrawerStore } from '@/store/drawer'
import { DeliveryPointType } from '@/variables/DeliveryPointType'
import { ArticleCode } from '@/variables/ArticleCode'
import type { AmountData } from '@/components/Cart/Module/AddItem/workflow'

const cartSetting: ModuleBuilder<CksNCartSettingsWorkflow> = {
  config: moduleCKSCConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow(record: { showAmount: boolean; showEvent: boolean; showDelivery: boolean }) {
        const i18n = useI18n()
        const cartStore = useCartStore()

        const itemConfig = cartStore
          .cartItemConfigs(cart.meta.productCode, cart.meta.articleCode)
          ?.find((config) => config.type === CartItemType.Card && config.nominative === true)
        if (itemConfig == null) {
          throw new Error('cart.errors.cartItemConfig.notFound')
        }

        const showEvent = record.showEvent && cart.meta.articleCode !== ArticleCode.CARTE_KADEOS_CULTURE
        const amount = cart.meta.dataStore[DataStoreKey.Amount]?.amount ?? itemConfig.recommendedAmount ?? null
        const events = cartStore.eventsByProductCode(cart.meta.productCode)
        const dataStoreDelivery = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
          ? cart.meta.dataStore[DataStoreKey.DeliveryPoint][cart.meta.productCode]
          : null
        const state: CksNImportOptionsState = reactive({
          isMultiAmount: cart.meta.dataStore[DataStoreKey.Amount]?.isMulti ?? false,
          isMultiEvent: cart.meta.dataStore[DataStoreKey.Event]?.isMulti ?? false,
          amount: amount ? { value: amount, hasDecomposition: false, decompositions: [] } : null,
          event: cart.meta.dataStore[DataStoreKey.Event]?.event ?? (events.length > 0 ? events[0] : null),
        })

        const rules = {
          isMultiAmount: {},
          amount: {
            required: helpers.withMessage(
              i18n.t('cart.modules.settings.amount.errors.required').toString(),
              requiredIf(() => !state.isMultiAmount && record.showAmount),
            ),
            integer: helpers.withMessage(
              i18n.t('cart.modules.addItem.amount.errors.integer').toString(),
              (value: AmountData) => Number.isInteger(value.value / 100),
            ),
            between: helpers.withMessage(
              () =>
                i18n
                  .t('cart.modules.addItem.amount.errors.between', {
                    min: toPrice(itemConfig.minAmount),
                    max: toPrice(itemConfig.maxAmount),
                  })
                  .toString(),
              (amount: AmountData) => amount.value >= itemConfig.minAmount && amount.value <= itemConfig.maxAmount,
            ),
          },
          isMultiEvent: {},
          event: {
            required: helpers.withMessage(
              i18n.t('cart.modules.addItem.events.errors.required').toString(),
              requiredIf(() => showEvent && !state.isMultiEvent),
            ),
          },
        }

        const v$ = useVuelidate(rules, state)
        return {
          events,
          showAmount: record.showAmount,
          showEvent,
          v$,
          saveDefaultParams: ref(false),
          showDelivery: record.showDelivery,
          cartItemConfig: itemConfig,
          deliveryProductImg: cart.meta.visual.src,
          deliveryTitle: 'cart.modules.settings.deliveryPoint.title',
          useDeliveryPointWorkflow: useDeliveryPointWorkflow(cart, dataStoreDelivery, {
            showType: true,
            productCode: cart.meta.productCode,
            for: 'import',
            showCardBenefDescription: true,
          }),
          submitAction: (submitDeliveryPoint) =>
            useAction({
              id: 'add',
              name: 'cart.modules.settings.save',
              type: ActionType.Default,
              refresh: true,
              async execute() {
                const dataStore: FrontCartDataStore = {
                  ...cart.meta.dataStore,
                }

                v$.value.$touch()

                if (v$.value.$invalid) {
                  return Result.err(new Error(getErrorMessage(v$.value.$errors)))
                }

                if (showEvent && v$.value.event.$model == null) {
                  return Result.err(new Error())
                }

                if (record.showDelivery && submitDeliveryPoint) {
                  const resultDeliveryPoint = await submitDeliveryPoint()

                  if (resultDeliveryPoint.isErr) {
                    return Result.err(resultDeliveryPoint.error)
                  }

                  dataStore[DataStoreKey.DeliveryPoint] = {
                    [cart.meta.productCode]: resultDeliveryPoint.value,
                  }

                  cartStore.edit({
                    localId: cart.localId,
                    meta: {
                      isHomeDelivery: resultDeliveryPoint.value.type === DeliveryPointType.PL_BENEF,
                    },
                  })
                }

                if (record.showAmount) {
                  dataStore[DataStoreKey.Amount] = {
                    isMulti: v$.value.isMultiAmount.$model,
                    amount: v$.value.amount.$model?.value ?? 0,
                  }
                }

                if (showEvent) {
                  dataStore[DataStoreKey.Event] = {
                    event: v$.value.event.$model,
                    isMulti: v$.value.isMultiEvent.$model,
                  }
                }

                cartStore.edit({
                  localId: cart.localId,
                  meta: {
                    dataStore,
                  },
                })

                const drawerStore = useDrawerStore()
                drawerStore.pop(moduleCKSCConfig.id)
                return Result.ok(true)
              },
            }),
        }
      },
    }
  },
}
export default cartSetting
