import type { RouteConfig } from 'vue-router'
import { CartRouteType, cartRouteTypeToStoreType } from './types'
import { accessor } from '@/store'
import { FrontCartType } from '@/store/cart/model'
import { useStore as useCartStore } from '@/store/cart/store'
import { ProductCode } from '@/variables/ProductCode'
import { universByProductCode, type Product, type ProductOptions } from '@/assets/constantes/Univers'
import { useStore as useToasterStore } from '@/store/toast'
import { AlertType } from '@/types/Alert'
import { ArticleCode } from '@/variables/ArticleCode'
import type { PermissionDetails } from '@/store/session/model'
import cartWorkflows from '@/store/cart/cartWorkflows'

async function getConfig(
  productCode: ProductCode,
  articleCode: ArticleCode | null,
  options: ProductOptions,
): Promise<{ product: Product; permissionsDetail: PermissionDetails } | null> {
  const json = await accessor.session.univers.find((univers) => univers.univers === universByProductCode[productCode])
  if (!json) {
    return null
  }

  const product = json.product.find((p) => {
    const keys = Object.keys(options)
    const key = keys.find((key) => p.options && p.options[key] && options[key] !== p.options[key])
    return p.code === productCode && p.articleCode === articleCode && !key
  })

  if (!product) {
    return null
  }

  const abilities = Array.isArray(product.abilities) ? product.abilities : [product.abilities]
  if (!accessor.session.hasPermission(abilities)) {
    return null
  }

  const permissionsDetail = accessor.session.getPermissionsDetails.find((e) => abilities.includes(e.tag))
  if (!permissionsDetail) {
    return null
  }

  if (
    product.articleCode === ArticleCode.CARTE_KADEOS_ZENITH_CE &&
    accessor.session.hasPermission('KS__CKSZENDEA') &&
    !accessor.session.hasPermission('KS__CKSZENCEA')
  ) {
    product.articleCode = ArticleCode.CARTE_KADEOS_ZENITH_DE
  }

  return {
    product,
    permissionsDetail,
  }
}

export const router: RouteConfig = {
  path: '/panier',
  meta: {
    isPublicUrl: true,
  },
  component: () => import('@/components/Cart/CartRoot.vue'),
  children: [
    {
      path: 'nouveau',
      beforeEnter: async (to, _, next) => {
        const toastStore = useToasterStore()

        if (typeof to.query.productCode !== 'string') {
          toastStore.push({
            title: 'Panier',
            description: 'Produit introuvable',
            type: AlertType.DANGER,
            autoHide: false,
          })
          next('/')
          return
        }

        if (typeof to.query.articleCode !== 'string' && to.query.articleCode !== undefined) {
          toastStore.push({
            title: 'Panier',
            description: 'Article introuvable',
            type: AlertType.DANGER,
            autoHide: false,
          })
          next('/')
          return
        }

        const { productCode, articleCode, ...rest } = to.query

        const options: ProductOptions = {
          isMixed: rest.isMixed === 'true',
          isNominative: rest.isNominative === 'true',
          isPreOrder: rest.isPreOrder === 'true',
          isPlasticless: rest.isPlasticless === 'true',
          isOneClick: rest.isOneClick === 'true',
          isBenefitsCalculator: rest.isBenefitsCalculator === 'true',
        }

        const isValidProductCode = Object.keys(ProductCode).includes(productCode)
        if (!isValidProductCode) {
          toastStore.push({
            title: 'Panier',
            description: 'Mauvais code produit',
            type: AlertType.DANGER,
            autoHide: false,
          })
          next('/')
          return
        }

        const isValidArticleCode = articleCode ? Object.keys(ArticleCode).includes(articleCode) : true
        if (!isValidArticleCode) {
          toastStore.push({
            title: 'Panier',
            description: 'Mauvais code article',
            type: AlertType.DANGER,
            autoHide: false,
          })
          next('/')
          return
        }

        const config = await getConfig(
          Number.parseInt(productCode) as ProductCode,
          articleCode ? (Number.parseInt(articleCode) as ArticleCode) : null,
          options,
        )

        if (!config) {
          toastStore.push({
            title: 'Panier',
            description: "Vous n'avez pas les permissions",
            type: AlertType.DANGER,
            autoHide: false,
          })
          next('/')
          return
        }

        const store = useCartStore()
        const result = await store.new({
          productCode: Number.parseInt(productCode) as ProductCode,
          articleCode: config.product.articleCode,
          product: config.product,
          ...options,
        })

        if (result.isErr) {
          if (result.error.message === 'cart.errors.inactiveContract') {
            toastStore.push({
              title: 'Contrat inactif',
              description:
                'Merci de vous rapprocher de votre commercial ou bien du centre de relation client au 0821 23 24 25.',
              type: AlertType.DANGER,
              autoHide: false,
            })
          } else {
            toastStore.push({
              title: 'Panier',
              description: result.error.message,
              type: AlertType.DANGER,
              autoHide: false,
            })
          }
          next('/')
          return
        }

        const workflow = cartWorkflows.find((cartWorkflow) => cartWorkflow.isValid(store.carts[result.value]))
        if (workflow == null) {
          next('/404')
          return
        }

        if (options.isBenefitsCalculator) {
          next(`/panier/l/${result.value}/import`)
        } else {
          next(`/panier/l/${result.value}`)
        }
      },
      component: () => import('@/components/Cart/Cart.vue'),
      props: {
        loading: true,
      },
    },
    {
      path: 'l/:id/:step?',
      beforeEnter: async (to, __, next) => {
        const { id } = to.params
        const store = useCartStore()
        const cart = store.carts[id]
        if (!cart) {
          next('/404')
          return
        }

        const workflow = cartWorkflows.find((cartWorkflow) => cartWorkflow.isValid(cart))
        if (workflow == null) {
          next('/404')
          return
        }

        next()
      },
      component: () => import('@/components/Cart/Cart.vue'),
      props: (route) => {
        const { id, step } = route.params

        return {
          id,
          path: step ?? '',
          record: route.query,
        }
      },
    },
    {
      path: ':type/:id',
      beforeEnter: async (to, __, next) => {
        // Call store resume
        const { id, type } = to.params

        const cartStoreType = cartRouteTypeToStoreType[type as CartRouteType]
        if (!cartStoreType || cartStoreType === FrontCartType.Local) {
          next('/404')
          return
        }

        const toastStore = useToasterStore()
        const store = useCartStore()
        const remoteId = parseInt(id)

        const result = await store.fetchCartByRemoteId(remoteId, cartStoreType)
        if (result.isErr) {
          if (result.error.message === 'cart.errors.inactiveContract') {
            toastStore.push({
              title: 'Contrat inactif',
              description:
                'Merci de vous rapprocher de votre commercial ou bien du centre de relation client au 0821 23 24 25.',
              type: AlertType.DANGER,
              autoHide: false,
            })
          }

          next('/404')
        } else {
          next(`/panier/l/${result.value}`)
        }
      },
      component: () => import('@/components/Cart/Cart.vue'),
    },
  ],
}
