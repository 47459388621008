import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { Result } from '@badrap/result'
import isDateAfter from 'date-fns/isAfter'
import { useModalStore } from '../modal'
import apiV4 from '@/services/apiV4'
import { MigrationStatus, type MigrationInformations } from '@/services/migration/types'
import contentful from '@/services/contentful'
import { formatDate } from '@/utils/date'

export const useMigrationStore = defineStore('migration', () => {
  const modalStore = useModalStore()
  const isAfterMigrationDate = computed(() =>
    informations.value?.contractMigrationEndDate
      ? isDateAfter(new Date(), new Date(informations.value.contractMigrationEndDate))
      : false,
  )
  const informations = ref<MigrationInformations | null>(null)
  const redirectLink = ref<string | null>(null)
  const showHomeModal = ref(true)
  const showCartModal = ref(true)

  const parseDescription = (description?: string | null) => {
    if (!informations.value?.contractMigrationEndDate || !description) {
      return description
    }
    return description.replace('{date_fin}', formatDate(informations.value?.contractMigrationEndDate) ?? '')
  }

  const fetchInformations = async () => {
    if (informations.value) {
      return Result.ok(informations.value)
    }

    const result = await apiV4.migration.getMigrationInformations()

    if (result.isOk) {
      informations.value = result.value

      return Result.ok(informations.value)
    } else {
      return Result.err(result.error)
    }
  }

  const fetchRedirectLink = async () => {
    if (redirectLink.value) {
      return Result.ok(redirectLink.value)
    }

    const result = await apiV4.migration.getMigrationRedirect()

    if (result.isOk) {
      redirectLink.value = result.value?.redirectLink ?? null

      return Result.ok(redirectLink.value)
    } else {
      return Result.err(result.error)
    }
  }

  const fetchHomeBanner = async () => {
    let bannerId: string | null = null
    switch (informations.value?.contractMigrationState) {
      case MigrationStatus.Targeted:
        bannerId = isAfterMigrationDate.value ? null : 'a1-migration'
        break
      case MigrationStatus.InProgress:
        bannerId = isAfterMigrationDate.value ? null : 'a1b-migration'
        break
      case MigrationStatus.Engaged:
        bannerId = isAfterMigrationDate.value ? null : 'a2-migration'
        break
      case MigrationStatus.Migrated:
        bannerId = isAfterMigrationDate.value ? 'a6-migration' : 'a3-migration'
        break
    }

    if (bannerId === null) {
      return null
    }

    const result = await contentful.banner.fetchBanner(bannerId)
    if (result.isErr) {
      return null
    }

    return { ...result.value, description: parseDescription(result.value.description) }
  }

  const loadHomeModal = async () => {
    if (!showHomeModal.value || isAfterMigrationDate.value) {
      return null
    }

    let modalId: string | null = null
    switch (informations.value?.contractMigrationState) {
      case MigrationStatus.Targeted:
        modalId = 'p1-migration'
        break
      case MigrationStatus.InProgress:
        modalId = 'p1b-migration'
        break
      case MigrationStatus.Engaged:
        modalId = 'p2-migration'
        break
      case MigrationStatus.Migrated:
        modalId = 'p3-migration'
        break
    }

    if (modalId === null) {
      return null
    }

    const result = await contentful.modal.fetchModal(modalId)
    if (result.isErr) {
      return null
    }

    modalStore.push(result.value.id, () => import('@/components/popins/ContentfulPopin.vue'), {
      id: result.value.id,
      cfModal: { ...result.value, description: parseDescription(result.value.description) },
      mainClick: () => {
        modalStore.pop(result.value.id)
      },
      cancelClick: () => {
        modalStore.pop(result.value.id)
      },
    })

    showHomeModal.value = false
  }

  const fetchCartModal = async () => {
    if (!showCartModal.value || isAfterMigrationDate.value) {
      return null
    }

    let modalId: string | null = null
    switch (informations.value?.contractMigrationState) {
      case MigrationStatus.Targeted:
        modalId = 'pt1-migration'
        break
      case MigrationStatus.InProgress:
        modalId = 'pt1b-migration'
        break
      case MigrationStatus.Engaged:
        modalId = 'pt2-migration'
        break
    }

    if (modalId === null) {
      return null
    }

    const result = await contentful.modal.fetchModal(modalId)
    if (result.isErr) {
      return null
    }

    return {
      ...result.value,
      description: parseDescription(result.value.description),
    }
  }

  const fetchOrderConfirmationCard = async () => {
    if (!informations.value || isAfterMigrationDate.value) {
      return null
    }

    let cardId: string | null = null

    switch (informations.value?.contractMigrationState) {
      case MigrationStatus.Targeted:
        cardId = 'e1-migration'
        break

      case MigrationStatus.InProgress:
        cardId = 'e1b-migration'
        break

      case MigrationStatus.Engaged:
        cardId = 'e2-migration'
        break
    }

    if (cardId === null) {
      return null
    }

    const result = await contentful.orderConfirmationCard.fetchOrderConfirmationCard(cardId)

    if (result.isErr) {
      return null
    }

    return result.value
  }

  const resetState = () => {
    informations.value = null
    redirectLink.value = null
    showHomeModal.value = true
    showCartModal.value = true
  }

  return {
    isAfterMigrationDate,
    informations,
    redirectLink,
    showCartModal,
    fetchInformations,
    fetchRedirectLink,
    fetchHomeBanner,
    resetState,
    fetchCartModal,
    loadHomeModal,
    fetchOrderConfirmationCard,
  }
})
