import {
  KADEOS_PAPER_NO_NOMINATIF,
  KADEOS_CARD_NO_NOMINATIF,
  KADEOS_CULTURE_CARD_NO_NOMINATIF,
  KADEOS_PAPER_CULTURE_NO_NOMINATIF,
} from '@/assets/constantes/tunnelsConfigs'
import univers from '@/assets/constantes/univers.json'
import { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'

const AnalysisView = () => import('@/views/uTr/Analysis/AnalysisView.vue')
const ConfirmationView = () => import('@/views/uTr/ConfirmationView.vue')
const NominativeOrderView = () => import('@/views/uTr/NominativeOrderView.vue')
const OptionsView = () => import('@/views/uTr/OptionsView/index.vue')
const SummaryView = () => import('@/views/uTr/Summary/SummaryView.vue')
const CommandeView = () => import('@/views/Commande/index.vue')
const Tunnel = () => import('@/views/Tunnel.vue')
const StandAloneConfirmationView = () => import('@/views/uTr/StandAloneConfirmationView.vue')
const ChildView = () => import('@/views/DefaultRouter/Child/index.vue')
const TunnelWrapper = () => import('@/views/Commande/tunnels/TunnelWrapper/index.vue')
const Order = () => import('@/views/Commande/tunnels/OrderView/index.vue')
const Finalisation = () => import('@/views/Commande/tunnels/Finalisation/index.vue')
const ConfirmationGenerique = () => import('@/views/Commande/tunnels/ConfirmationGenerique/index.vue')

export default {
  path: univers.TK.ROOT.path,
  // TODO: Replace with RootView when migrate univers
  component: CommandeView,
  meta: univers.TK.ROOT.meta,
  children: [
    {
      path: univers.TK.UNIVERS.path,
      meta: univers.TK.UNIVERS.meta,
      name: univers.TK.UNIVERS.name,
      component: () => import('@/views/Universe/index.vue'),
    },
    {
      path: univers.TK.CULTURE_ROOT.path,
      component: ChildView,
      children: [
        {
          path: univers.TK.NEW_CULTURE_PAPIER_ROOT.path,
          component: ChildView,
          children: [
            {
              path: univers.TK['NEW_CULTURE_PAPIER_NON-NOM'].path,
              meta: univers.TK['NEW_CULTURE_PAPIER_NON-NOM'].meta,
              name: univers.TK['NEW_CULTURE_PAPIER_NON-NOM'].name,
              component: TunnelWrapper,
              props: {
                univers: 'TK',
                isNominatif: false,
                productCode: ProductCode.KADEOS,
                articleCode: ArticleCode.KADEOS_CULTURE,
                hideStepperPaths: [univers.TK.CONFIRMATION_KADEOS_CULTURE.path],
                hideHeadingPaths: [univers.TK.CONFIRMATION_KADEOS_CULTURE.path],
                headingTitle: 'Passer une commande de <strong>titres Kadéos Culture</strong>',
                headingButton: 'howTo',
              },
              children: [
                {
                  path: univers.TK['NEW_CULTURE_PAPIER_NON-NOM_ORDER'].path,
                  component: Order,
                  name: univers.TK['NEW_CULTURE_PAPIER_NON-NOM_ORDER'].name,
                  step: {
                    number: 1,
                    label: 'Panier',
                    icon: 'basket',
                  },
                  props: {
                    operationnalConfig: KADEOS_PAPER_CULTURE_NO_NOMINATIF,
                  },
                },
                {
                  path: univers.TK['FINALISATION__KADEOS_CULTURE'].path,
                  component: Finalisation,
                  name: univers.TK['FINALISATION__KADEOS_CULTURE'].name,
                  step: {
                    number: 2,
                    label: 'Paiement',
                    icon: 'card-picto',
                  },
                },
                {
                  path: univers.TK.CONFIRMATION_KADEOS_CULTURE.path,
                  meta: univers.TK.CONFIRMATION_KADEOS_CULTURE.meta,
                  name: univers.TK.CONFIRMATION_KADEOS_CULTURE.name,
                  component: ConfirmationGenerique,
                  step: {
                    number: 3,
                    label: 'confirmation',
                    icon: 'card-picto',
                    ishidden: true,
                  },
                },
              ],
            },
          ],
        },
        {
          path: univers.TK.NEW_CULTURE_CARTE_ROOT.path,
          component: ChildView,
          children: [
            {
              path: univers.TK['NEW_CULTURE_CARTE_NON-NOM'].path,
              meta: univers.TK['NEW_CULTURE_CARTE_NON-NOM'].meta,
              name: univers.TK['NEW_CULTURE_CARTE_NON-NOM'].name,
              component: TunnelWrapper,
              props: {
                univers: 'TK',
                isNominatif: false,
                productCode: ProductCode.CARTE_KADEOS,
                articleCode: ArticleCode.CARTE_KADEOS_CULTURE,
                hideStepperPaths: [univers.TK.CONFIRMATION_CARTE_KADEOS_CULTURE.path],
                hideHeadingPaths: [univers.TK.CONFIRMATION_CARTE_KADEOS_CULTURE.path],
                headingTitle: 'Passer une commande de <strong>cartes Kadéos Culture</strong>',
                headingButton: 'howTo',
              },
              children: [
                {
                  path: univers.TK['NEW_CULTURE_CARTE_NON-NOM_ORDER'].path,
                  component: Order,
                  name: univers.TK['NEW_CULTURE_CARTE_NON-NOM_ORDER'].name,
                  step: {
                    number: 1,
                    label: 'Panier',
                    icon: 'basket',
                  },
                  props: {
                    operationnalConfig: KADEOS_CULTURE_CARD_NO_NOMINATIF,
                  },
                },
                {
                  path: univers.TK['FINALISATION__CARTE_KADEOS_CULTURE'].path,
                  component: Finalisation,
                  name: univers.TK['FINALISATION__CARTE_KADEOS_CULTURE'].name,
                  step: {
                    number: 2,
                    label: 'Paiement',
                    icon: 'card-picto',
                  },
                },
                {
                  path: univers.TK.CONFIRMATION_CARTE_KADEOS_CULTURE.path,
                  meta: univers.TK.CONFIRMATION_CARTE_KADEOS_CULTURE.meta,
                  name: univers.TK.CONFIRMATION_CARTE_KADEOS_CULTURE.name,
                  component: ConfirmationGenerique,
                  step: {
                    number: 3,
                    label: 'confirmation',
                    icon: 'card-picto',
                    ishidden: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: univers.TK.ZENITH_ROOT.path,
      component: ChildView,
      children: [
        {
          path: univers.TK.NEW_ZENITH_CARTE_ROOT.path,
          component: ChildView,
          children: [
            {
              path: univers.TK['NEW_ZENITH_CARTE_NON-NOM'].path,
              meta: univers.TK['NEW_ZENITH_CARTE_NON-NOM'].meta,
              name: univers.TK['NEW_ZENITH_CARTE_NON-NOM'].name,
              component: TunnelWrapper,
              props: {
                univers: 'TK',
                isNominatif: false,
                productCode: ProductCode.CARTE_KADEOS,
                articleCode: [ArticleCode.CARTE_KADEOS_ZENITH_CE, ArticleCode.CARTE_KADEOS_ZENITH_DE],
                hideStepperPaths: [univers.TK.CONFIRMATION_CARTE_KADEOS_ZENITH.path],
                hideHeadingPaths: [univers.TK.CONFIRMATION_CARTE_KADEOS_ZENITH.path],
                headingTitle: 'Passer une commande de <strong>cartes Kadéos Zenith</strong>',
                headingButton: 'howTo',
              },
              children: [
                {
                  path: univers.TK['NEW_ZENITH_CARTE_NON-NOM_ORDER'].path,
                  component: Order,
                  name: univers.TK['NEW_ZENITH_CARTE_NON-NOM_ORDER'].name,
                  step: {
                    number: 1,
                    label: 'Panier',
                    icon: 'basket',
                  },
                  props: {
                    operationnalConfig: KADEOS_CARD_NO_NOMINATIF,
                  },
                },
                {
                  path: univers.TK['FINALISATION__CARTE_KADEOS_ZENITH'].path,
                  component: Finalisation,
                  name: univers.TK['FINALISATION__CARTE_KADEOS_ZENITH'].name,
                  step: {
                    number: 2,
                    label: 'Paiement',
                    icon: 'card-picto',
                  },
                },
                {
                  path: univers.TK.CONFIRMATION_CARTE_KADEOS_ZENITH.path,
                  meta: univers.TK.CONFIRMATION_CARTE_KADEOS_ZENITH.meta,
                  name: univers.TK.CONFIRMATION_CARTE_KADEOS_ZENITH.name,
                  component: ConfirmationGenerique,
                  step: {
                    number: 3,
                    label: 'confirmation',
                    icon: 'card-picto',
                    ishidden: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: univers.TK.INFINI_ROOT.path,
      component: ChildView,
      children: [
        {
          path: univers.TK.NEW_INFINI_PAPIER_ROOT.path,
          component: ChildView,
          children: [
            {
              path: univers.TK['NEW_INFINI_PAPIER_NON-NOM'].path,
              meta: univers.TK['NEW_INFINI_PAPIER_NON-NOM'].meta,
              name: univers.TK['NEW_INFINI_PAPIER_NON-NOM'].name,
              component: TunnelWrapper,
              props: {
                univers: 'TK',
                isNominatif: false,
                productCode: ProductCode.KADEOS,
                articleCode: ArticleCode.KADEOS_INFINI,
                hideStepperPaths: [univers.TK.CONFIRMATION_PAPIER_KADEOS_INFINI.path],
                hideHeadingPaths: [univers.TK.CONFIRMATION_PAPIER_KADEOS_INFINI.path],
                headingTitle: 'Passer une commande de <strong>titres Kadéos Infini</strong>',
                headingButton: 'howTo',
              },
              children: [
                {
                  path: univers.TK['NEW_INFINI_PAPIER_NON-NOM_ORDER'].path,
                  component: Order,
                  name: univers.TK['NEW_INFINI_PAPIER_NON-NOM_ORDER'].name,
                  step: {
                    number: 1,
                    label: 'Panier',
                    icon: 'basket',
                  },
                  props: {
                    operationnalConfig: KADEOS_PAPER_NO_NOMINATIF,
                  },
                },
                {
                  path: univers.TK['FINALISATION__PAPIER_KADEOS_INFINI'].path,
                  component: Finalisation,
                  name: univers.TK['FINALISATION__PAPIER_KADEOS_INFINI'].name,
                  step: {
                    number: 2,
                    label: 'Paiement',
                    icon: 'card-picto',
                  },
                },
                {
                  path: univers.TK.CONFIRMATION_PAPIER_KADEOS_INFINI.path,
                  meta: univers.TK.CONFIRMATION_PAPIER_KADEOS_INFINI.meta,
                  name: univers.TK.CONFIRMATION_PAPIER_KADEOS_INFINI.name,
                  component: ConfirmationGenerique,
                  step: {
                    number: 3,
                    label: 'confirmation',
                    icon: 'card-picto',
                    ishidden: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: univers.TK.DROM_ROOT.path,
      component: ChildView,
      children: [
        {
          path: univers.TK.NEW_DROM_PAPIER_ROOT.path,
          component: ChildView,
          children: [
            {
              path: univers.TK['NEW_DROM_PAPIER_NON-NOM'].path,
              meta: univers.TK['NEW_DROM_PAPIER_NON-NOM'].meta,
              name: univers.TK['NEW_DROM_PAPIER_NON-NOM'].name,
              component: TunnelWrapper,
              props: {
                univers: 'TK',
                isNominatif: false,
                productCode: ProductCode.KADEOS,
                articleCode: ArticleCode.KADEOS_INFINI_DOM,
                hideStepperPaths: [univers.TK.CONFIRMATION_PAPIER_KADEOS_DROM.path],
                hideHeadingPaths: [univers.TK.CONFIRMATION_PAPIER_KADEOS_DROM.path],
                headingTitle: 'Passer une commande de <strong>titres Kadéos Infini DROM</strong>',
                headingButton: 'howTo',
              },
              children: [
                {
                  path: univers.TK['NEW_DROM_PAPIER_NON-NOM_ORDER'].path,
                  component: Order,
                  name: univers.TK['NEW_DROM_PAPIER_NON-NOM_ORDER'].name,
                  step: {
                    number: 1,
                    label: 'Panier',
                    icon: 'basket',
                  },
                  props: {
                    operationnalConfig: KADEOS_PAPER_NO_NOMINATIF,
                  },
                },
                {
                  path: univers.TK['FINALISATION__PAPIER_KADEOS_DROM'].path,
                  component: Finalisation,
                  name: univers.TK['FINALISATION__PAPIER_KADEOS_DROM'].name,
                  step: {
                    number: 2,
                    label: 'Paiement',
                    icon: 'card-picto',
                  },
                },
                {
                  path: univers.TK.CONFIRMATION_PAPIER_KADEOS_DROM.path,
                  meta: univers.TK.CONFIRMATION_PAPIER_KADEOS_DROM.meta,
                  name: univers.TK.CONFIRMATION_PAPIER_KADEOS_DROM.name,
                  component: ConfirmationGenerique,
                  step: {
                    number: 3,
                    label: 'confirmation',
                    icon: 'card-picto',
                    ishidden: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: univers.TK.SELECT_ROOT.path,
      component: ChildView,
      children: [
        {
          path: univers.TK.NEW_SELECT_PAPIER_ROOT.path,
          component: ChildView,
          children: [
            {
              path: univers.TK['NEW_SELECT_PAPIER_NON-NOM'].path,
              meta: univers.TK['NEW_SELECT_PAPIER_NON-NOM'].meta,
              name: univers.TK['NEW_SELECT_PAPIER_NON-NOM'].name,
              component: TunnelWrapper,
              props: {
                univers: 'TK',
                isNominatif: false,
                productCode: ProductCode.KADEOS,
                articleCode: ArticleCode.KADEOS_SELECT,
                hideStepperPaths: [univers.TK.CONFIRMATION_PAPIER_KADEOS_SELECT.path],
                hideHeadingPaths: [univers.TK.CONFIRMATION_PAPIER_KADEOS_SELECT.path],
                headingTitle: 'Passer une commande de <strong>titres Kadéos Select</strong>',
                headingButton: 'howTo',
              },
              children: [
                {
                  path: univers.TK['NEW_SELECT_PAPIER_NON-NOM_ORDER'].path,
                  component: Order,
                  name: univers.TK['NEW_SELECT_PAPIER_NON-NOM_ORDER'].name,
                  step: {
                    number: 1,
                    label: 'Panier',
                    icon: 'basket',
                  },
                  props: {
                    operationnalConfig: KADEOS_PAPER_NO_NOMINATIF,
                  },
                },
                {
                  path: univers.TK['FINALISATION__PAPIER_KADEOS_SELECT'].path,
                  component: Finalisation,
                  name: univers.TK['FINALISATION__PAPIER_KADEOS_SELECT'].name,
                  step: {
                    number: 2,
                    label: 'Paiement',
                    icon: 'card-picto',
                  },
                },
                {
                  path: univers.TK.CONFIRMATION_PAPIER_KADEOS_SELECT.path,
                  meta: univers.TK.CONFIRMATION_PAPIER_KADEOS_SELECT.meta,
                  name: univers.TK.CONFIRMATION_PAPIER_KADEOS_SELECT.name,
                  component: ConfirmationGenerique,
                  step: {
                    number: 3,
                    label: 'confirmation',
                    icon: 'card-picto',
                    ishidden: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: univers.TK.CONNECT_ROOT.path,
      meta: univers.TK.CONNECT_ROOT.meta,
      name: univers.TK.CONNECT_ROOT.name,
      component: Tunnel,
      props: {
        productCode: ProductCode.KADEOS_CONNECT,
        articleCode: ArticleCode.KADEOS_CONNECT,
        componentList: [OptionsView, NominativeOrderView, AnalysisView, SummaryView, ConfirmationView],
        headingTitle: 'Passer une commande <strong>Kadéos Connect</strong>',
        tunnelName: 'KSC',
        stepper: [
          {
            number: 1,
            label: 'Panier',
            icon: 'basket',
            steps: [{ number: 1, label: 'Options' }],
          },
          {
            number: 2,
            label: 'Commande',
            icon: 'user',
            steps: [
              { number: 2, label: 'Commande' },
              { number: 3, label: 'Analyse' },
            ],
          },
          {
            number: 3,
            label: 'Paiement',
            icon: 'card-picto',
            steps: [{ number: 4, label: 'Finalisation' }],
          },
        ],
      },
    },
    {
      path: univers.TK.CONFIRMATION_ROOT.path,
      meta: univers.TK.CONFIRMATION_ROOT.meta,
      name: univers.TK.CONFIRMATION_ROOT.name,
      component: StandAloneConfirmationView,
      props: (route) => {
        return {
          orderId: Number(route.query.orderId),
        }
      },
    },
  ],
}
