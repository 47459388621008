import { Result } from '@badrap/result'
import { useItemFormWorkflow } from '../composables/useItemFormWorkflow'
import type { RemoteCart, UseAction } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import type { Beneficiary } from '@/services/beneficiaries/types'
import { useBeneficiaryFormWorkflow } from '@/composables/useBeneficiaryFormWorkflow'
import type { AddBeneficiaryItemWorkflow } from '@/components/Cart/Module/Beneficiary/workflow'

export const useAddBeneficiaryItemWorkflow: (
  cart: RemoteCart,
  useAction: UseAction,
  record?: { beneficiary?: Beneficiary },
) => AddBeneficiaryItemWorkflow = (cart, useAction, record) => {
  const itemFormWorkflow = useItemFormWorkflow(cart)

  return {
    title: 'cart.modules.addItemBeneficiary.title',
    useBeneficiaryFormWorkflow: useBeneficiaryFormWorkflow(
      cart.meta.productCode,
      cart.meta.isPlasticless,
      record?.beneficiary ?? undefined,
    ),
    cancelAction: null,
    submitAction: (submitBenef) =>
      useAction({
        id: 'add',
        name: 'add',
        type: ActionType.Default,
        refresh: true,
        async execute() {
          const resultBenef = await submitBenef()
          if (resultBenef.isErr) {
            return Result.err(resultBenef.error)
          }

          const resultItemForm = await itemFormWorkflow.submit(resultBenef.value)
          if (resultItemForm.isErr) {
            return Result.err(resultItemForm.error)
          }

          return Result.ok(true)
        },
      }),
  }
}
