import { useBillingContent, useDelivery } from '@/store/cart/cartWorkflows/common/steps/payment'
import payment from '@/store/cart/cartWorkflows/univers/TR/common/steps/payment'
import type { PaymentWorkflow } from '@/components/Cart/Step/Payment/workflow'
import type { StepBuilder } from '@/store/cart/model'
import type { DeliveryInformations } from '@/services/carts/types'

const step: StepBuilder<PaymentWorkflow> = {
  ...payment,
  useStep(
    cart,
    workflow,
    record: { deliveryInformations: Record<number, DeliveryInformations>; totalItems: number },
    useAction,
  ) {
    const useStep = payment.useStep(cart, workflow, record, useAction)
    return {
      ...useStep,
      useWorkflow() {
        const useWorkflow = useStep.useWorkflow()
        const deliveryInformation = record.deliveryInformations[cart.remote.id]
        return {
          ...useWorkflow,
          dataProps: [
            {
              deliveries: [
                useDelivery(cart.remote, deliveryInformation, {
                  title: 'cart.delivery.loadCardTitle',
                  canChangedDate: true,
                  type: 'dematerialized',
                  productCode: cart.remote.description.productCode,
                  articleCode: cart.remote.description.articleCode,
                  deliveryDate: cart.remote.paymentOptions.deliveryDate,
                }),
                useDelivery(cart.remote, deliveryInformation, {
                  title: 'cart.delivery.cardTitle',
                  canChangedDate: false,
                  type: 'default',
                  description: cart.remote.options.isHomeDelivery
                    ? 'cart.delivery.description'
                    : 'cart.delivery.atCompanyDescription',
                  productCode: cart.remote.description.productCode,
                  articleCode: cart.remote.description.articleCode,
                  deliveryDate: null,
                }),
              ],

              billing: {
                title: 'cart.billing.title',
                description: 'cart.billing.description',
                right: deliveryInformation.billingAddress
                  ? useBillingContent(cart, deliveryInformation.billingAddress)
                  : null,
                productCode: cart.remote.description.productCode,
                articleCode: cart.remote.description.articleCode,
              },
            },
          ],
        }
      },
    }
  },
}

export default step
