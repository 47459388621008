import basket from '../../../common/steps/basket'
import type { BasketWorkflow } from '@/components/Cart/Step/Basket/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { moduleConfig as clearCartPopupConfig } from '@/components/popins/ClearCart/workflow'
import { stepConfig as importStepConfig } from '@/components/Cart/Step/Import/workflow'
import { ActionType } from '@/types/Action'
import { DataStoreKey } from '@/services/dataStore/types'
import settings from '@/store/cart/cartWorkflows/common/module/settings/CKSN'
import { ArticleCode } from '@/variables/ArticleCode'

const step: StepBuilder<BasketWorkflow> = {
  ...basket,

  useStep(cart, workflow, record, useAction) {
    const useStep = basket.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const useWorkflow = useStep.useWorkflow()

        useWorkflow.table.value.rowComponent = () => import('@/components/Cart/Step/Basket/Row/KSNRow.vue')
        useWorkflow.table.value.columns = [
          { id: 'beneficiary', label: 'table.headers.beneficiary' },
          { id: 'event', label: 'table.headers.event' },
          { id: 'amount', label: 'table.headers.amount' },
          { id: 'delivery', label: 'table.headers.delivery' },
        ]

        if (cart.meta.articleCode === ArticleCode.CARTE_KADEOS_CULTURE) {
          useWorkflow.table.value.columns.splice(1, 1)
        }

        const dataStoreAmount = cart.meta.dataStore[DataStoreKey.Amount]
        const dataStoreEvent = cart.meta.dataStore[DataStoreKey.Event]
        const dataStoreDeliveries = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
        const dataStoreDeliveryPoint = dataStoreDeliveries ? dataStoreDeliveries[cart.meta.productCode] : null
        const showEvent = cart.meta.articleCode !== ArticleCode.CARTE_KADEOS_CULTURE

        return {
          ...useWorkflow,
          hasAdd: false,
          hasImport: true,
          addAction: null,
          showSettings:
            !dataStoreAmount || (showEvent && !dataStoreEvent) || (!cart.meta.isCrossSell && !dataStoreDeliveryPoint),
          settingsAction: useAction({
            name: 'settings',
            id: settings.config.id,
            type: ActionType.Drawer,
            refresh: false,
            payload: {
              showEvent: true,
              showAmount: true,
              showDelivery: !cart.meta.isCrossSell,
              close: false,
            },
          }),
          importAction: useAction({
            id: importStepConfig.id,
            type: ActionType.Step,
            name: 'cart.button.importCsv',
            icon: 'document-upload',
            refresh: false,
          }),
          reimportAction: useAction({
            id: clearCartPopupConfig.id,
            type: ActionType.Popin,
            name: 'cart.button.reimportCsv',
            icon: 'document-upload',
            refresh: false,
            payload: { key: 'reimportFile' },
          }),
        }
      },
    }
  },
}

export default step
