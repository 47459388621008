import { computed } from 'vue'
import basket from '../../common/steps/basket'
import addBeneficiary from '@/store/cart/cartWorkflows/common/module/addBeneficiary'
import type { BasketWorkflow } from '@/components/Cart/Step/Basket/workflow'
import type { StepBuilder } from '@/store/cart/model'
import habilitations from '@/variables/habilitations.json'
import { accessor } from '@/store'
import { useStore as useDrawerStore } from '@/store/drawer'

const step: StepBuilder<BasketWorkflow> = {
  ...basket,
  useStep(cart, workflow, record, useAction) {
    const useStep = basket.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const commonWorkflow = useStep.useWorkflow()

        const hasAdd = accessor.session.hasPermission(habilitations.TUNNEL.CTR_SAISIE_WEB)
        const hasImport = accessor.session.hasPermission(habilitations.TUNNEL.CTR_IMPORT)

        const drawerStore = useDrawerStore()

        return {
          ...commonWorkflow,
          hasAdd,
          hasImport,
          showActions: computed(
            () =>
              commonWorkflow.response.data.value?.totalElements === 0 &&
              hasAdd &&
              hasImport &&
              drawerStore.pages.find((page) => page.id === addBeneficiary.config.id) == null,
          ),
        }
      },
    }
  },
}

export default step
