import { Result } from '@badrap/result'
import type { Asset, ContentfulClientApi, Entry } from 'contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { INLINES } from '@contentful/rich-text-types'
import type { TypeBannerSkeleton } from '../generated'
import { mapMediaToLocal } from '../types/CFMedia'
import type { CFTarget } from '../types/CFTarget'
import type { CFBanner, CFPageType } from '../types/CFBanner'
import type { SimpleLink } from '@/types/Link'
import type { ProductCode } from '@/variables/ProductCode'

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchBanner = async (id: string) => {
    try {
      const result = await contentfulInstance.getEntries<TypeBannerSkeleton>({
        content_type: 'banner',
        'fields.id': id,
        'fields.target': 'EC',
      })

      const items = mapBannersToLocal(result.items)

      if (items.length === 0) {
        return Result.err(new Error('Banner not found'))
      }

      return Result.ok(items[0])
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  const fetchBanners = async (page: CFPageType) => {
    try {
      const result = await contentfulInstance.getEntries<TypeBannerSkeleton>({
        content_type: 'banner',
        'fields.page': page,
        'fields.target': 'EC',
      })
      return Result.ok(mapBannersToLocal(result.items))
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  return {
    fetchBanner,
    fetchBanners,
  }
}

export function mapBannerToLocal(item: Entry<TypeBannerSkeleton, undefined, string>): CFBanner {
  return {
    ...item.fields,
    description: item.fields.description
      ? documentToHtmlString(item.fields.description, {
          renderNode: {
            [INLINES.HYPERLINK]: (node, next) => {
              return `<a class="btn-link" target="_blank" href=${node.data.uri}>${next(node.content)}</a>`
            },
          },
        })
      : null,
    color: item.fields.color as unknown as string,
    cta: item.fields.cta as unknown as SimpleLink,
    products: item.fields.products?.map((product) => parseInt(product) as ProductCode) ?? [],
    divisions: item.fields.divisions?.map((division) => parseInt(division)) ?? [],
    image: mapMediaToLocal(item.fields.image as Asset<undefined, string>),
    page: item.fields.page as CFPageType,
    target: item.fields.target as CFTarget,
    backgroundColor: item.fields.backgroundColor as unknown as string,
  }
}

export function mapBannersToLocal(items: Entry<TypeBannerSkeleton, undefined, string>[]): CFBanner[] {
  return items.map((item) => mapBannerToLocal(item))
}
