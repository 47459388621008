import univers from '@/assets/constantes/univers.json'

const CommandeView = () => import('@/views/Commande/index.vue')

export default {
  path: univers.TS.ROOT.path,
  // TODO: Replace with RootView when migrate univers
  component: CommandeView,
  meta: univers.TS.ROOT.meta,
  children: [
    {
      path: univers.TS.UNIVERS.path,
      meta: univers.TS.UNIVERS.meta,
      name: univers.TS.UNIVERS.name,
      component: () => import('@/views/Universe/index.vue'),
    },
  ],
}
