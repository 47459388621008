import { computed, ref, watch } from 'vue'
import { Result } from '@badrap/result'
import { useAddBeneficiaryWorkflow } from '../composables/useAddBeneficiaryWorkflow'
import { useAddBeneficiaryItemWorkflow } from './addItemBeneficiary'
import {
  type AddBeneficiariesWorkflow,
  moduleConfig,
  type Tab,
  type BeneficiaryWorkflow,
} from '@/components/Cart/Module/Beneficiary/workflow'
import { usePagination } from '@/composables/usePagination'
import apiV4 from '@/services/apiV4'
import type { Beneficiary } from '@/services/beneficiaries/types'
import type { ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import useSwrv from '@/utils/useSwrv'
import settings from '@/store/cart/cartWorkflows/common/module/settings/Tr'
import type { Table } from '@/types/Table'
import type { CartItemRequest } from '@/services/carts/types'
import { useI18n } from '@/composables/useI18n'
import { AlertType } from '@/types/Alert'
import { useStore as useToasterStore } from '@/store/toast'

const addBenef: ModuleBuilder<AddBeneficiariesWorkflow> = {
  config: moduleConfig,
  isAllowed: () => true,
  useModule(cartRef, workflow, useAction) {
    return {
      useWorkflow({ nbItems }: { nbItems: number }) {
        const i18n = useI18n()
        const toasterStore = useToasterStore()
        if (cartRef.value === null) {
          throw new Error('cart.errors.cartNotFound')
        }

        let { value: cart } = cartRef

        const addedAll = ref(false)

        const module = workflow.modules.find((module) => module.config.id === moduleConfig.id)
        if (!module) {
          throw new Error()
        }
        const paginationData = usePagination<Beneficiary>()

        const table = ref<Table>({
          columns: [
            {
              id: 'beneficiary',
              label: 'table.headers.beneficiary',
              sort: 'name',
            },
            { id: 'quantity', label: 'table.headers.quantity' },

            { id: 'action', label: '' },
          ],
          selectable: false,
          loading: false,
          readonly: false,
          rowComponent: () => import('@/components/Cart/Module/Beneficiary/subs/BeneficiaryRow.vue'),
        })

        const beneficiariesResponses = useSwrv(
          () => `beneficiaries?${paginationData.queries.value}`,
          async () => {
            const result = await apiV4.beneficiaries.fetchBeneficiaries({
              page: paginationData.currentPage.value,
              sort: paginationData.sort.value,
              sortDirection: paginationData.sortDirection.value,
              filter: paginationData.filter.value,
              pageSize: paginationData.pageSize.value,
            })
            if (result.isOk) {
              paginationData.paginatedData.value = result.value
              return {
                paginateData: result.value,
                workflows: result.value.items.map((beneficiary) =>
                  useAddBeneficiaryWorkflow(cart, beneficiary, useAction, true),
                ),
              }
            } else {
              throw result.error
            }
          },
        )
        const tabs: Tab[] = [
          {
            id: 'add',
            label: 'cart.modules.beneficiary.addBeneficiary',
            icon: 'user-tick',
          },
          {
            id: 'create',
            label: 'cart.modules.beneficiary.new',
            icon: 'add-circle',
          },
        ]

        watch(
          cartRef,
          () => {
            if (cartRef.value === null) {
              throw new Error('cart.errors.cartNotFound')
            }

            cart = cartRef.value
          },
          { deep: true },
        )

        return {
          tabs,
          tab: ref(tabs[0]),
          useAddTabWorkflow: () => ({
            paginationData,
            beneficiariesResponses,
            table,
            valueOfTitleAction: useAction({
              name: 'cart.import.settingsAction.amount',
              id: settings.config.id,
              type: ActionType.Drawer,
              refresh: false,
              payload: {
                showDelivery: false,
                showValueOfTitle: true,
                for: 'input',
              },
            }),
            deliveryPointAction: !cart.meta.isPlasticless
              ? useAction({
                  name: 'cart.import.settingsAction.deliveryPoint',
                  id: settings.config.id,
                  type: ActionType.Drawer,
                  refresh: false,
                  payload: {
                    showDelivery: true,
                    showValueOfTitle: false,
                    for: 'input',
                  },
                })
              : null,
            addAllBeneficiariesAction: computed(() => {
              if (
                addedAll.value ||
                nbItems !== 0 ||
                beneficiariesResponses.data.value == null ||
                beneficiariesResponses.data.value.paginateData.totalElements > 200
              ) {
                return null
              }

              return useAction({
                name: 'cart.modules.beneficiary.add',
                id: 'add-all-beneficiaries',
                type: ActionType.Default,
                refresh: true,
                async execute() {
                  if (beneficiariesResponses.data.value == null) {
                    return Result.err(new Error('No benef'))
                  }
                  const total = beneficiariesResponses.data.value.paginateData.totalElements

                  const pageSize = 100
                  const beneficiariesResults = await Promise.all(
                    Array.from({ length: Math.ceil(total / pageSize) }, (_, page) =>
                      apiV4.beneficiaries.fetchBeneficiaries({
                        page: page + 1,
                        pageSize,
                      }),
                    ),
                  )

                  const workflows = beneficiariesResults.reduce<BeneficiaryWorkflow[][]>((r, benefResult) => {
                    if (benefResult.isErr) {
                      return r
                    }
                    r.push(
                      benefResult.value.items.map((beneficiary) =>
                        useAddBeneficiaryWorkflow(cart, beneficiary, useAction),
                      ),
                    )
                    return r
                  }, [])

                  const mappedData = workflows.reduce<{
                    requests: CartItemRequest[][]
                    missingBeneficiaries: Beneficiary[]
                  }>(
                    (r, workflow) => {
                      const data = workflow.reduce<{
                        requests: CartItemRequest[]
                        missingBeneficiaries: Beneficiary[]
                      }>(
                        (r, workflow) => {
                          const request = workflow.fetchCartItemRequest()
                          if (request) {
                            r.requests.push(request)
                          } else {
                            r.missingBeneficiaries.push(workflow.beneficiary)
                          }

                          return r
                        },
                        { requests: [], missingBeneficiaries: [] },
                      )
                      r.requests.push(data.requests)
                      r.missingBeneficiaries.push(...data.missingBeneficiaries)
                      return r
                    },
                    { requests: [], missingBeneficiaries: [] },
                  )

                  const results = await Promise.all(
                    mappedData.requests.map((request) => apiV4.carts.postItemsBatch(cart.remote.id, request)),
                  )
                  if (
                    results.filter((result) => result.isOk).length === results.length &&
                    mappedData.missingBeneficiaries.length === 0
                  ) {
                    toasterStore.push({
                      title: i18n.t('cart.modules.beneficiary.title').toString(),
                      description: i18n.t('cart.modules.beneficiary.success.default').toString(),
                      type: AlertType.SUCCESS,
                      autoHide: false,
                    })
                  } else {
                    const count = mappedData.missingBeneficiaries.length
                    const lastMappedData = count > 1 ? mappedData.missingBeneficiaries.pop() : null
                    toasterStore.push({
                      title: i18n.t('cart.modules.beneficiary.title').toString(),
                      description: i18n.tc('cart.modules.beneficiary.success.withError', count, {
                        names: mappedData.missingBeneficiaries
                          .map((beneficiary) => {
                            return [beneficiary.firstName, beneficiary.name].filter((name) => name).join(' ')
                          })
                          .join(' ,'),
                        lastName: lastMappedData,
                      }),
                      type: AlertType.WARNING,
                      autoHide: false,
                    })
                  }

                  addedAll.value = results.filter((result) => result.isErr).length !== results.length

                  return Result.ok(true)
                },
              })
            }),
          }),
          useCreateTabWorkflow: () => useAddBeneficiaryItemWorkflow(cart, useAction),
        }
      },
    }
  },
}

export default addBenef
