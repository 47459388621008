const baseConfig = {
  validations: [],
  universName: null,
}

const validators = [
  {
    name: 'universName',
    validator: hasValue,
    message: 'universName REQUIRED for configuration, please provide valid univers route name',
  },
  {
    name: 'validations',
    validator: hasOneOrMoreValidation,
    message: 'At least one validition REQUIRED for configuration, please provide at least one validation',
  },
]

function hasValue(data) {
  return !!data
}

function hasValidEntry(config, { name, validator, message }) {
  if (config[name] == null || !validator(config[name])) {
    console.error(message)
    return false
  }
  return true
}

function hasOneOrMoreValidation(dataObject) {
  return dataObject.filter((entry) => hasValue(entry)).length
}

export default (config) => {
  const isValidConfProvide = validators.map((validator) => hasValidEntry(config, validator))
  if (isValidConfProvide.indexOf(false) !== -1) {
    console.error('Invalid orderView Configuration provide')
  }
  return Object.assign({}, baseConfig, config)
}
