import { Result } from '@badrap/result'
import { usePricingSummary } from '@/store/cart/cartWorkflows/common/usePricing'
import importStep, { useCommonStep } from '@/store/cart/cartWorkflows/common/steps/import'
import { ImportStatus, type FieldResponse, type ImportWorkflow } from '@/components/Cart/Step/Import/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { mapRulesToLocal } from '@/types/Import'
import { DeliveryPointType } from '@/variables/DeliveryPointType'
import apiV4 from '@/services/apiV4'
import useSwrv from '@/utils/useSwrv'
import { useI18n } from '@/composables/useI18n'
import { toPrice } from '@/utils/price'
import { fetchSeparator } from '@/utils/csv'
import { ActionType } from '@/types/Action'
import { DataStoreKey } from '@/services/dataStore/types'
import settings from '@/store/cart/cartWorkflows/common/module/settings/KSC'
import { ArticleCode } from '@/variables/ArticleCode'

const step: StepBuilder<ImportWorkflow> = {
  ...importStep,
  useStep(cart, workflow, record, useAction) {
    const useStep = importStep.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const i18n = useI18n()
        const commonWorkflow = useCommonStep(cart, workflow, record, useAction)
        commonWorkflow.reimportAllowed.value = false
        const dataStoreAmount = cart.meta.dataStore[DataStoreKey.Amount]
        const dataStoreEvent = cart.meta.dataStore[DataStoreKey.Event]
        const dataStoreDeliveries = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
        const dataStoreDeliveryPoint = dataStoreDeliveries ? dataStoreDeliveries[cart.meta.productCode] : null
        const showEvent = cart.meta.articleCode !== ArticleCode.CARTE_KADEOS_CULTURE
        const showDelivery = !cart.meta.isCrossSell
        const fieldResponse = useSwrv<FieldResponse>(`carts/import/rules/${cart.meta.productCode}`, async () => {
          const fields = await apiV4.fileUpload.fetchFields(cart.meta.productCode, {
            hasEvent: !dataStoreEvent?.isMulti,
            hasAmount: !dataStoreAmount?.isMulti,
            hasDeliveryPoint: !dataStoreDeliveryPoint?.isMulti,
            deliverToBeneficiary: dataStoreDeliveryPoint?.type === DeliveryPointType.PL_BENEF,
          })

          if (fields.isErr) {
            throw fields.error
          }

          return {
            productCode: cart.meta.productCode,
            type: DeliveryPointType.PL_BENEF,
            fields: mapRulesToLocal(cart.meta.productCode, fields.value, record.importFields),
          }
        })

        const actionConstructor = {
          name: 'settings',
          id: settings.config.id,
          type: ActionType.Drawer,
          refresh: false,
          payload: {
            showDelivery: true,
            showEvent: true,
            showAmount: true,
          },
        }
        const settingActions = [
          useAction({
            ...actionConstructor,
            name: i18n
              .t('cart.import.settingsAction.deliveryPoint', {
                deliveryPointType:
                  dataStoreDeliveryPoint === null
                    ? i18n.t('toDefine').toString()
                    : dataStoreDeliveryPoint?.type === DeliveryPointType.PL_BENEF
                    ? i18n.t('cart.modules.settings.deliveryPoint.beneficiary.title')
                    : i18n.t('cart.modules.settings.deliveryPoint.company.title'),
              })
              .toString(),
            payload: {
              showDelivery: true,
              showEvent: false,
              showAmount: false,
            },
          }),
          useAction({
            ...actionConstructor,
            name: i18n
              .t('cart.import.settingsAction.amount', {
                amount:
                  dataStoreAmount === null
                    ? i18n.t('toDefine').toString()
                    : dataStoreAmount.isMulti
                    ? i18n.t('cart.modules.settings.amount.isMulti').toString()
                    : toPrice(dataStoreAmount.amount),
              })
              .toString(),
            payload: {
              showDelivery: false,
              showEvent: false,
              showAmount: true,
            },
          }),
        ]

        if (showEvent) {
          settingActions.push(
            useAction({
              ...actionConstructor,
              name: i18n
                .t('cart.import.settingsAction.event', {
                  event:
                    dataStoreEvent?.event == null
                      ? i18n.t('toDefine').toString()
                      : dataStoreEvent.isMulti
                      ? i18n.t('cart.modules.addItem.events.multi.toggle').toString()
                      : dataStoreEvent.event.label,
                })
                .toString(),
              payload: {
                showDelivery: false,
                showEvent: true,
                showAmount: false,
              },
            }),
          )
        }

        return {
          ...commonWorkflow,
          settingActions,
          importAction: useAction({
            name: 'importFile.importAction',
            id: 'import-file',
            icon: 'document-download',
            type: ActionType.Default,
            refresh: false,
            async execute() {
              if (
                dataStoreAmount === null ||
                (showEvent && dataStoreEvent === null) ||
                (showDelivery && dataStoreDeliveryPoint === null)
              ) {
                return Result.err(new Error('cart.import.errors.settingKscNotConfigured'))
              }

              if (commonWorkflow.file.value == null) {
                return Result.err(new Error('cart.import.errors.fileNotExist'))
              }

              const resultSeparator = await fetchSeparator(commonWorkflow.file.value)
              if (resultSeparator.isErr) {
                return Result.err(resultSeparator.error)
              }

              commonWorkflow.status.value = ImportStatus.Waiting
              commonWorkflow.filename.value = commonWorkflow.file.value.name

              const result = await apiV4.carts.postImport(cart.remote.id, {
                fileUpload: commonWorkflow.file.value,
                eventCode: dataStoreEvent?.isMulti ? undefined : dataStoreEvent?.event?.value,
                facialValue: dataStoreAmount?.isMulti ? undefined : dataStoreAmount?.amount,
                deliveryPointReference: !dataStoreDeliveryPoint?.isMulti
                  ? dataStoreDeliveryPoint?.reference ?? undefined
                  : undefined,
                separator: resultSeparator.value,
              })

              if (result.isErr) {
                commonWorkflow.status.value = ImportStatus.Error
                return Result.err(result.error)
              }

              commonWorkflow.importId.value = result.value.id
              commonWorkflow.filename.value = commonWorkflow.file.value.name

              commonWorkflow.setImportInterval()

              return Result.ok(true)
            },
          }),
          pricings: [usePricingSummary(cart)],
          fieldResponse,
          downloadTemplateWithDataAction: null,
        }
      },
    }
  },
}

export default step
