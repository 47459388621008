// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Geocoder from '@pderas/vue2-geocoder'
import * as Sentry from '@sentry/vue'
// eslint-disable-next-line import/no-unresolved
import 'virtual:vite-plugin-sentry/sentry-config'
import { createPinia, PiniaVuePlugin } from 'pinia'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import VueMask from 'v-mask'
import Vue from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueI18n from 'vue-i18n'
import VueMq from 'vue-mq'

import { createI18n } from './composables/useI18n'
import router from './router'
import store from './store'
import LastPath from '@/assets/js/lastPath'
import jsonDico from '@/lang.json'
import { googleMapsApiKey, SENTRY_DSN, vueEnv, clickUrl, clientApiUrl, clientApiV4, clientUrlRedUp } from '@/parameters'
import VueTracking from '@/plugins/tracking'
import api from '@/plugins/api'
import apiV3 from '@/plugins/apiV3'
import apiV4 from '@/plugins/apiV4'
import Lang from '@/plugins/lang'

import '@popperjs/core/dist/umd/popper.min'
import 'bootstrap'
import '@/helpers/numeral'

const lastPath = new LastPath()

Vue.use(VueTracking)
Vue.use(VueI18n)
Vue.use(PortalVue)
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: Infinity,
  },
})

Vue.use(Geocoder, {
  defaultCountryCode: 'FR',
  googleMapsApiKey,
})

Vue.config.productionTip = false
Vue.use(Lang, { jsonDico })
Vue.use(VueMask)
Vue.use(api)
Vue.use(apiV4)
Vue.use(apiV3)
Vue.use(VueDOMPurifyHTML)
Vue.use(VCalendar)

router.beforeEach((to, _from, next) => {
  lastPath.set(to.fullPath)

  next()
})

// SENTRY
if (SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
    environment: vueEnv,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', clickUrl, clientApiUrl, clientApiV4, clientUrlRedUp],
      }),
    ],
    tracesSampleRate: 0.01,
    sendClientReports: false,
  })
}

Vue.component('MigrationLayout', () => import('@/layouts/MigrationLayout.vue'))
Vue.component('DefaultLayout', () => import('@/layouts/DefaultLayout.vue'))
Vue.component('AuthLayout', () => import('@/layouts/AuthLayout.vue'))
Vue.component('PublicLayout', () => import('@/layouts/PublicLayout.vue'))
Vue.component('Main', () => import('@/Main.vue'))

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  render: (h) => h('Main'),
  pinia,
  i18n: createI18n(),
})
