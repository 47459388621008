import { Result } from '@badrap/result'
import importStep, { useCommonStep } from '@/store/cart/cartWorkflows/common/steps/import'
import { ImportStatus, type FieldResponse, type ImportWorkflow } from '@/components/Cart/Step/Import/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { mapRulesToLocal } from '@/types/Import'
import { DeliveryPointType } from '@/variables/DeliveryPointType'
import apiV4 from '@/services/apiV4'
import useSwrv from '@/utils/useSwrv'
import { toPrice } from '@/utils/price'
import { useI18n } from '@/composables/useI18n'
import { DataStoreKey } from '@/services/dataStore/types'
import options from '@/store/cart/cartWorkflows/common/module/settings/Mixed'
import { ActionType } from '@/types/Action'
import { accessor } from '@/store'
import habilitations from '@/variables/habilitations.json'
import type { PostImportRequestBody } from '@/services/carts/typesV2'
import { fetchSeparator } from '@/utils/csv'
import { usePricingSummary } from '@/store/cart/cartWorkflows/common/usePricing'

const step: StepBuilder<ImportWorkflow> = {
  ...importStep,
  isAllowed(cart) {
    if (!importStep.isAllowed(cart)) {
      return false
    }

    return accessor.session.hasPermission([habilitations.TUNNEL.CTR_IMPORT])
  },
  useStep(cart, workflow, record, useAction) {
    const useStep = importStep.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const i18n = useI18n()
        const commonWorkflow = useCommonStep(cart, workflow, record, useAction)
        commonWorkflow.reimportAllowed.value = false
        const dataStoreValueOfTitle = cart.meta.dataStore[DataStoreKey.ValueOfTitle]

        const fieldResponse = useSwrv<FieldResponse>(`carts/import/rules/${cart.meta.productCode}`, async () => {
          const fields = await apiV4.fileUpload.fetchFields(cart.meta.productCode, {
            hasFacialValue: dataStoreValueOfTitle ? !dataStoreValueOfTitle.isMulti : false,
            hasDeliveryPoint: true,
          })

          if (fields.isErr) {
            throw fields.error
          }

          return {
            productCode: cart.meta.productCode,
            type: DeliveryPointType.PL_BENEF,
            fields: mapRulesToLocal(cart.meta.productCode, fields.value, record.importFields),
          }
        })

        return {
          ...commonWorkflow,
          pricings: [usePricingSummary(cart)],
          fieldResponse,
          options: [
            i18n
              .t('cart.import.settingsAction.amount', {
                amount: dataStoreValueOfTitle?.isMulti
                  ? i18n.t('cart.modules.settings.valueOfTitle.isMultiValueOfTitle').toString()
                  : `${toPrice(dataStoreValueOfTitle?.value ?? 0)} - ${
                      (dataStoreValueOfTitle?.employersContribution ?? 0) / 100
                    }%`,
              })
              .toString(),
          ],
          settingActions: [
            useAction({
              id: options.config.id,
              type: ActionType.Drawer,
              refresh: false,
              name: i18n
                .t('cart.import.settingsAction.amount', {
                  amount:
                    dataStoreValueOfTitle === null
                      ? i18n.t('toDefine').toString()
                      : dataStoreValueOfTitle.isMulti
                      ? i18n.t('cart.modules.settings.valueOfTitle.isMultiValueOfTitle').toString()
                      : `${toPrice(dataStoreValueOfTitle.value ?? 0)} - ${
                          (dataStoreValueOfTitle.employersContribution ?? 0) / 100
                        }%`,
                })
                .toString(),
              payload: {
                showDelivery: false,
                showValueOfTitle: true,
                for: 'import',
              },
            }),
          ],
          importAction: useAction({
            name: 'importFile.importAction',
            id: 'import-file',
            icon: 'document-upload',
            type: ActionType.Default,
            refresh: false,
            async execute() {
              if (dataStoreValueOfTitle === null) {
                return Result.err(new Error('cart.import.errors.settingCtrvNotConfigured'))
              }
              if (commonWorkflow.file.value == null) {
                return Result.err(new Error('cart.import.errors.fileNotExist'))
              }

              let deliveryPointReference: string | null = null
              try {
                const deliveries = await apiV4.deliveryPoints.getDeliveryPointsListV4({
                  deliveryPointsType: DeliveryPointType.PL_BENEF,
                  page: 1,
                  pageSize: 1,
                  productCode: cart.meta.productCode,
                })
                if (deliveries.data.items.length > 0) {
                  deliveryPointReference = deliveries.data.items[0].reference
                }
              } catch (error) {
                return Result.err(error as Error)
              }

              if (deliveryPointReference === null) {
                return Result.err(new Error('Delivery Point is null'))
              }
              const resultSeparator = await fetchSeparator(commonWorkflow.file.value)
              if (resultSeparator.isErr) {
                return Result.err(resultSeparator.error)
              }

              commonWorkflow.status.value = ImportStatus.Waiting
              commonWorkflow.filename.value = commonWorkflow.file.value.name

              const requestBody: PostImportRequestBody = {
                fileUpload: commonWorkflow.file.value,
                facialValue: dataStoreValueOfTitle?.isMulti ? undefined : dataStoreValueOfTitle?.value,
                employersContribution: dataStoreValueOfTitle?.isMulti
                  ? undefined
                  : (dataStoreValueOfTitle?.employersContribution ?? 0) / 100,
                separator: resultSeparator.value,
                deliveryPointReference,
              }

              const result = await apiV4.carts.postImport(cart.remote.id, requestBody)

              if (result.isErr) {
                commonWorkflow.status.value = ImportStatus.Error
                return Result.err(result.error)
              }

              commonWorkflow.importId.value = result.value.id
              commonWorkflow.filename.value = commonWorkflow.file.value.name

              commonWorkflow.setImportInterval()
              return Result.ok(true)
            },
          }),
        }
      },
    }
  },
}

export default step
