import { Result } from '@badrap/result'
import { useVuelidate } from '@vuelidate/core'
import { computed, ref } from 'vue'
/* eslint-disable import/no-duplicates */
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays'
import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import { helpers, required, integer, between } from '@vuelidate/validators'
import { useVuelidateDeliveryPoint } from './useDeliveryPointWorkflow'
import { useVuelidateValueOfTitle } from './useValueOfTitleWorkflow'
import apiV4 from '@/services/apiV4'
import { fetchCartItemType, type CartItemRequest } from '@/services/carts/types'
import type { ValueOfTitleData } from '@/types/ValueOfTitle'
import { DataStoreKey } from '@/services/dataStore/types'
import type { UseItemFormWorkflow } from '@/components/Cart/Module/Beneficiary/workflow'
import type { Beneficiary } from '@/services/beneficiaries/types'

export const useItemFormWorkflow: UseItemFormWorkflow = (cart) => {
  const date = new Date()
  const maxPackagingQuantity = 222
  const quantity = ref(differenceInBusinessDays(endOfMonth(date), startOfMonth(date)))

  const rules = computed(() => ({
    quantity: {
      required: helpers.withMessage('cart.modules.addItemBeneficiary.itemFrom.quantity.errors.required', required),
      between: helpers.withMessage(
        () => `Saisissez une quantité entre ${0} et ${maxPackagingQuantity}`,
        between(0, maxPackagingQuantity),
      ),
      integer: helpers.withMessage('cart.modules.addItemBeneficiary.itemFrom.quantity.errors.integer', integer),
    },
  }))

  const dataStoreDelivery = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
    ? cart.meta.dataStore[DataStoreKey.DeliveryPoint][cart.meta.productCode]
    : null

  const dataStoreValueOfTitle = cart.meta.dataStore[DataStoreKey.ValueOfTitle]
  let defaultData: ValueOfTitleData | undefined = undefined
  if (dataStoreValueOfTitle) {
    defaultData = {
      value: dataStoreValueOfTitle.value,
      employersContribution: dataStoreValueOfTitle.employersContribution,
    }
  }

  const v$ = useVuelidate(rules, { quantity })
  const { v$: vtV$ } = useVuelidateValueOfTitle(defaultData)
  const { v$: deliveryV$ } = useVuelidateDeliveryPoint(cart, dataStoreDelivery, 'input')

  const fetchCartItemRequest = (beneficiary: Beneficiary): CartItemRequest => {
    return {
      itemType: fetchCartItemType(cart.remote.description.productCode, cart.remote.options.isPlasticless),
      deliveryPointReference: dataStoreDelivery?.reference ?? null,
      distributionPointReference: dataStoreDelivery?.distributionPoint?.reference ?? null,
      packagingQuantity: 1,
      compositions: [
        {
          quantity: quantity.value,
          titleValue: defaultData?.value ?? null,
          employersContribution: defaultData?.employersContribution ? defaultData.employersContribution / 100 : null,
        },
      ],
      beneficiary,
    }
  }

  return {
    quantity,
    fetchCartItemRequest,
    submit: async (beneficiary) => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return Result.err(new Error(v$.value.$errors[0].$message.toString()))
      }

      vtV$.value.$touch()
      if (vtV$.value.$invalid) {
        return Result.err(new Error(vtV$.value.$errors[0].$message.toString()))
      }

      if (!cart.meta.isPlasticless) {
        deliveryV$.value.$touch()
        if (deliveryV$.value.$invalid) {
          return Result.err(new Error(deliveryV$.value.$errors[0].$message.toString()))
        }
      }

      const body = fetchCartItemRequest(beneficiary)
      const resultItem = await apiV4.carts.postItem(cart.remote.id, body)

      if (resultItem.isErr) {
        return Result.err(resultItem.error)
      }

      return Result.ok(undefined)
    },
  }
}
