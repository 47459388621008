import commonsCartWorkflow from '../common'
import stepBasket from './steps/basket'
import importStep from './steps/import'
import type { FrontCart, CartWorkflow } from '@/store/cart/model'
import { ProductCode } from '@/variables/ProductCode'

const steps = commonsCartWorkflow.steps.map((step) => {
  switch (step.config.id) {
    case stepBasket.config.id:
      return stepBasket

    case importStep.config.id:
      return importStep
  }

  return step
})

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  isValid(cart: FrontCart) {
    return cart.meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT && cart.meta.isMultiLoading
  },
}

export default cartWorkflow
